import React from 'react'

const Penalty = () => {
    return (
        <table width="100%" height="100%"  class="forTexts"> <tbody><tr> <td width="100%" height="100%" valign="top">  <h3>Transactions History:</h3> 
        <form method="post" name="trans">
             <table class="form controls nosize"> <tbody><tr> <td> <select name="ttype" class="inpts" onchange="document.trans.submit()"> <option value="">All Transactions</option>  <option value="withdraw_pending">Withdrawal request</option>  <option value="add_funds">Transfer from external processings</option>  <option value="deposit">Deposit</option>  <option value="bonus">Bonus</option>  <option value="penalty" selected="">Penalty</option>  <option value="earning">Earning</option>  <option value="withdrawal">Withdrawal</option>  <option value="commissions">Referral commission</option>  <option value="exchange">Exchange</option>  <option value="internal_transaction">Internal Transaction</option>  <option value="rcb">RCB</option>  </select><br /> <select name="ec" class="inpts" onchange="document.trans.submit()"> <option value="-1">All eCurrencies</option>  <option value="48">Bitcoin</option>  <option value="69">Ethereum</option>  <option value="1002">Ethereum</option>  <option value="1000">Bitcoin</option>  </select> </td> 
             
                 </tr> </tbody></table> </form> <table class="list"> <tbody><tr> <th>UserName</th> <th>Amount</th> <th>Date</th> </tr>   </tbody></table> <br /> <center> <ul class="pagination"><li class="page-item"><a class="prev page-link disabled">&lt;&lt;</a></li><li class="page-item active"><a class="page-link">1</a></li><li class="page-item"><a class="next page-link disabled">&gt;&gt;</a></li></ul> </center>  </td> </tr> </tbody></table>
    )
}

export default Penalty
