import React from 'react'
import {Link} from "react-router-dom"

const Transaction = () => {

    const transactionsArr = [
        {username: "Ikechukwu", amount: "$496.42", date: "Jan-13-2022 :09 PM", transaction_type: "Withdrawal", msg: " Withdraw processed to account 1EKYyWePdtvGVqgMSWam152gcUcSiupycr. Batch is 189c254d832b603c8d6a18fecd94073e6f901ab71f1a23ac7039e48c2b74d2dd", img: ""},
        {username: "prince23", amount: "$296.42", date: "Jan-13-2022 :09 PM", transaction_type: "Referral commission", msg: "Referral commission from sawakotobe@gmail.com", img: ""},
        {username: "Gollie2", amount: "$326.42", date: "Jan-13-2022 :09 PM", transaction_type: "Withdrawal request", msg: "Your comment", img: ""},
        {username: "Ikechukwu", amount: "$496.42", date: "Jan-13-2022 :09 PM", transaction_type: "Deposit", msg: "Deposit to 8% after 24 hours", img: ""},
    ]

    const mappTransactions = transactionsArr.reverse().map((v,i) => (
        
<tr key={i} class="row1"> <td class="trn_user">  <b>{v.username}</b> <Link class="badge badge-danger"  to="/edit-user/:id">edit</Link> <Link class="badge badge-info"  to="/edit-user-funds/:id">manage</Link> <br /> <small style={{color:"gray"}}><b>{v.transaction_type}:</b> &nbsp; {v.msg}</small> </td> <td nowrap="" align="right"><b style={{color:"red"}}>$496.42</b> <img src={require("../../btc.gif")} align="absmiddle" hspace="1" height="17" /></td> <td align="center"><small>{v.date}</small></td> </tr>


    ))


    return (
        <table width="100%" height="100%" border="0" cellpadding="10" cellspacing="0" class="forTexts"> <tbody><tr>
<td width="100%" height="100%" valign="top"> 
<h3>Transactions History:</h3>

<form method="post" name="trans"> <table class="form controls nosize"> <tbody><tr> <td>

     <br /> <select name="ec" class="inpts" onchange="document.trans.submit()"> <option value="-1">All eCurrencies</option>  <option value="48">Bitcoin</option>  <option value="68">Litecoin</option>  <option value="69">Ethereum</option>  <option value="1002">Ethereum</option>  <option value="1000">Bitcoin</option>  <option value="1001"></option>  <option value="0" selected=""></option>  </select> </td> </tr> </tbody></table> </form>









<table className="list">
<tbody>

<tr> <th>UserName</th> <th>Amount</th> <th>Date</th> </tr>



{mappTransactions}



<tr> <td><b>Total for selected options:</b></td> <td align="right" nowrap=""> $10152.46 <img src={require("../../btc.gif")} align="absmiddle" /><br /> </td> <td></td> </tr>







</tbody>
</table>


<center> <ul class="pagination"><li class="page-item"><a class="prev page-link disabled">&lt;&lt;</a></li><li class="page-item active"><a class="page-link">1</a></li><li class="page-item"><a class="page-link" href="javascript:go(2)">2</a></li><li class="page-item"><a class="page-link" href="javascript:go(3)">3</a></li><li class="page-item"><a class="next page-link" href="javascript:go(2)">&gt;&gt;</a></li><li class="page-item"><a class="last page-link" href="javascript:go(3)">&gt;&gt;&gt;</a></li></ul> </center>







</td>
            </tr>
</tbody>
</table>

    )
}

export default Transaction
