import React, {useEffect, useContext, useState} from 'react';
import {useParams, Link} from 'react-router-dom';
import {MainContext} from '../../App';
import toast, {Toaster} from 'react-hot-toast';

const UserDeposit = (props) => {
  const userID = useParams().id;
  const CTX = useContext(MainContext);
  const [deposits, setDeposit] = useState([]);

  // console.log('props', userID);

  const fetchUserDeposit = () => {
    let TOASTID = toast.loading('Settings up contents');
    fetch(`${CTX.url}admin/action/list-deposit/${userID}`, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `bearer ${CTX.token}`,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        toast.dismiss(TOASTID); // stop the loading icon
        toast.success('All deposits ');
        setDeposit(res.allDepositByUser);
      })
      .catch((e) => {
        toast.dismiss(TOASTID);
        toast.error('Error check your internet connection');
        console.log(e);
      });
  };

  useEffect(() => {
    fetchUserDeposit();
  }, []);

  const confirmDeposit = (id, newAmount, e) => {
    let TOASTID = toast.loading('Confirming deposit');
    fetch(`${CTX.url}admin/action/deposit/confirm/${id}/${userID}`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `bearer ${CTX.token}`,
        }),
        body: JSON.stringify({
          newAmount
        })
      })
        .then((res) => res.json())
        .then((res) => {
          toast.dismiss(TOASTID); // stop the loading icon
          toast.success(res.data);
          fetchUserDeposit()
        })
        .catch((e) => {
          toast.dismiss(TOASTID);
          toast.error('Error check your internet connection');
          console.log(e);
        });
  }



  const deleteDepositHandler = (id, e) => {
    let TOASTID = toast.loading('Deleting deposit');
    fetch(`${CTX.url}admin/action/deposit/delete/${id}`, {
        method: 'DELETE',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `bearer ${CTX.token}`,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          toast.dismiss(TOASTID); // stop the loading icon
          toast.success(res.data);
          fetchUserDeposit()
        })
        .catch((e) => {
          toast.dismiss(TOASTID);
          toast.error('Error check your internet connection');
          console.log(e);
        });
  }


  const onChangeInput = (i, e) => {
    const spread = [...deposits]
    console.log("i", i)
    console.log("spread[i] ==>> ", spread[i])
    console.log("i", i)
    spread[i].amount = e.target.value
    setDeposit(spread)
    // console.log("spread[i]", spread[i])
    // console.log("[i]", i)
    // console.log("e.target.value", e.target.value)
  }

  
  const mapAllDeposits = deposits?.map((v,i) => (
    <tr key={v, i} className="row1">
      <td className="trn_user">
        
        <b>{v?.user?.email}</b>
        <div style={{cursor: "pointer"}} className="badge badge-danger" onClick={deleteDepositHandler.bind(v, v._id)}>
          delete
        </div>
        {v.pending && <div style={{cursor: "pointer"}}
        onClick={confirmDeposit.bind(this, v._id, v.amount)}
          className="badge badge-info"
          to={`/edit-user-funds/${v?.user?._id}`}
        >
          confirm
        </div>}
        <br />
        <small style={{color: 'gray'}}>
          <b>Deposit to {v?.wallet} wallet</b> &nbsp; Earning ${v?.earning}
        </small>
      </td>
      <td nowrap="" align="right">
        <b>$</b>
        <input onChange={onChangeInput.bind(this,i)} style={{color: 'green', width: "40px", marginRight: "10px"}} value={v?.amount} />
      </td>
      <td align="center" style={{width: '77px'}}>
        {v.date}
      </td>
    </tr>
  ));

  return (
    <table width="100%" height="100%" class="forTexts">
      <Toaster position="top-right" />
      <tbody>
        <tr>
          
          <td width="100%" height="100%" valign="top">
            
            <h3>User's Deposits:</h3>
            <form method="post">
              <table class="list">
                
                <tbody>
                <tr>
                    
                    <th>Info</th> <th>Actions</th>
                  </tr>
                  
                  { mapAllDeposits.length < 1 ? <tr>
                    
                    <td colspan="3" align="center">
                      No deposits found
                    </td>
                  </tr>  : mapAllDeposits }
                </tbody>
              </table>
              <br />
            </form>
            <br />
            <div class="alert alert-warning">
              
              Manage deposits:
              <br /> A member can ask you to clear his deposit and return his
              funds.
              <br /> This screen helps you to release user's deposit if you
              need. Funds will return to the member's account and the member can
              withdraw these funds.
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default UserDeposit;
