import React from 'react'

const EarningHoliday = () => {
    return (
        <table width="100%" height="100%" border="0" className="forTexts">
             <tbody>
                 <tr>
                      <td width="100%" height="100%">
                          <h3>Earning Holidays</h3>  <table className="list"> <tbody><tr> <th>Date</th> <th>Description</th> <th>Action</th> </tr>  <tr> <td align="center" colspan="3">No Holidays</td> </tr>  </tbody></table> <br /><br />  <form method="post">
                               <table className="form"> <tbody><tr> <th>Date:</th> <td><input type="date" name="date" value="yyyy-mm-dd" className="inpts" /></td> </tr> <tr> <th>Description:</th> <td><input type="text" name="desc" value="" className="inpts" /></td> </tr> <tr> <th></th> <td><input type="submit" value="Add" className="sbmt" /></td> </tr> </tbody></table> </form> <br /><br /> <div className="alert alert-warning"> Here you can set any future day as holiday. All <b>Daily</b> plans will not pay earnings on holidays.<br /> Users will receive no earning with description: "no interest: holiday description".<br /> <b>Attention:</b> if you use plan with duration 5 days, and one of this day will be holiday, user will receive 4 earnings only.<br /> If you&amp;ll set date has passed as holiday, it will not be any affect on past earnings. </div>  </td> </tr> </tbody></table>
    )
}

export default EarningHoliday
