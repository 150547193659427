import React, {useState, useEffect, useContext} from 'react';
import toast, {Toaster} from 'react-hot-toast';
import {MainContext} from '../../App';
import { Link } from 'react-router-dom';


const User = () => {
  const CTX = useContext(MainContext);
  const [filterCU, setFilterCU] = useState('all');
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("")
  const [searchSelect, setSearchSelect] = useState("full_name")
  const [allUsers, setAllUsers] = useState([]);
  const users = [
    {name: "Lubna lono", balance:  "434", since: " Jan-12-2022", funded: "0.00", withdraw: "0.00", commission: "23.32", username: "", _id: "32r32der2e"},
    {name: "Lubna lono", balance:  "434", since: " Jan-12-2022", funded: "0.00", withdraw: "0.00", commission: "23.32", username: "", _id: "32r32der2e"},
    {name: "Lubna lono", balance:  "434", since: " Jan-12-2022", funded: "0.00", withdraw: "0.00", commission: "23.32", username: "", _id: "32r32der2e"},
  ]

  const mapUsers = allUsers.filter(v => v[searchSelect].toLowerCase().indexOf(searchInput.toLowerCase()) >= 0).map((v,i) => (
     <tr style={{marginBottom: "40px !important"}} id="user_134" className="row1"> <td valign="top"> <table width="100%" className="list sub"> <tbody><tr> <td> <input type="checkbox" name="users[134]" value="1" />  <b className="username">{v.full_name}</b>  <span className="badge badge-success">Active</span>   <br /> </td> </tr> 
     <tr><td>Username: <i>{v.username}</i></td></tr>
     <tr><td>Reg Date: <i>{v.reg_date}</i></td></tr> 
     {/* <tr><td>Since: Jan-12-2022</td></tr> */}
        <tr><td>    </td></tr> </tbody></table> </td> <td> <table width="100%" className="list sub"> <tbody><tr><td style={{width:"50%"}}>Balance:</td><td> <b style={{color:"navy"}}>${v?.balance}</b> </td></tr> <tr><td>Deposit:</td><td> <b style={{color:"gray"}}>${v?.total_deposit}</b> </td></tr> <tr><td>Withdraw:</td><td> <b style={{color:"gray"}}>${v?.total_withdrawal}</b> </td></tr> 
        
        {/* <tr><td>Commission:</td><td> <b style={{color:"gray"}}>$0.00</b> </td></tr> */}
          </tbody></table> </td> <td>  <Link to={`/edit-user/${v?._id}/${v?.username}`} className="sbmt btn-sm btn-success" style={{marginBottom:"2px"}}>edit</Link><br />   <Link to={`/edit-user-funds/${v?._id}/${v?.username}`} className="sbmt btn-sm btn-info" style={{marginBottom: "2px"}}>funds</Link> </td> </tr>
  ))



  const fetchAllUser = () => {
    setLoading(true);
    let TOASTID = toast.loading('Settings up contents');
    fetch(`${CTX.url}admin/action/user/`, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `bearer ${CTX.token}`,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        toast.dismiss(TOASTID); // stop the loading icon
        toast.success('All users ');
        setAllUsers(res.data.reverse());
        setLoading(false);
        // setCurrentLimit(res.previous_page * limit)
      })
      .catch((e) => {
        setLoading(false);
        toast.dismiss(TOASTID);
        toast.error('Error check your internet connection');
        console.log(e);
      });
  }

  useEffect(() => {
    fetchAllUser();
  }, []);

  return (
    <table
      width="100%"
      height="100%"
      border="0"
      cellpadding="10"
      cellspacing="0"
      className="forTexts"
    >
      <tbody>
        <tr>
            <td width="100%" height="100%" valign="top">
              <div style={{display: "flex", justifyContent: "space-between"}}>
            <h3>Users:
                  </h3>

<div style={{display: "flex", width: "max-content", height: "30px", overflow: "hidden", marginTop: "10px"}}>
<input style={{paddingLeft: "14px"}} value={searchInput} onChange={e => setSearchInput(e.target.value)}  />
<select onChange={e => setSearchSelect(e.target.value)}>
  <option value="full_name">Full Name</option>
  <option value="email">Email</option>
  <option value="reg_date">Date</option>
  <option value="username">Username</option>
</select>
</div>

              </div>

            <form>

            {/* <table className="form controls nosize"> <tbody><tr> <td>Search:</td> <td> <input type="text" name="q" value="" className="inpts nosize" size="30" /> </td> <td> Order: <select name="order" className="inpts nosize" onchange="sort(this)"> <option value="username-0">Username ↑</option> <option value="username-1">Username ↓</option> <option value="datereg-1">Registration ↑</option> <option value="datereg-0">Registration ↓</option> <option value="balance-1">Balance ↑</option> <option value="balance-0">Balance ↓</option> <option value="funded-1">Funded ↑</option> <option value="funded-0">Funded ↓</option> <option value="withdrawal-1">Withdrew ↑</option> <option value="withdrawal-0">Withdrew ↓</option> <option value="commissions-1">Commissions ↑</option> <option value="commissions-0">Commissions ↓</option>  </select> </td> <td> Per Page: <select name="onpage" className="inpts nosize" onchange="document.users.submit()"> <option value="20" selected="">20 </option><option value="50">50 </option><option value="100">100 </option></select> </td> </tr> <tr> <td>Status:</td> <td> <select name="status" className="inpts nosize"> <option value="">--All--</option>  <option value="on">Active</option>  <option value="off">Disabled</option>  <option value="suspended">Suspended</option>  <option value="blocked">Blocked</option>  <option value="not_confirmed">Not Confirmed</option>  </select> </td>  <td></td>   <td style={{textAlign: "right"}}> <input type="submit" value="Apply" className="sbmt" /> </td> </tr> </tbody></table> */}










            <table className="list"> <tbody>

            <tr> <th>User</th> <th>Amounts</th> <th>Actions</th> </tr>

            {/* <tr id="user_134" className="row1"> <td valign="top"> <table width="100%" className="list sub"> <tbody><tr> <td> <input type="checkbox" name="users[134]" value="1" />  <b className="username">Lubna11</b>  <span className="badge badge-success">Active</span>   <br /> </td> </tr> <tr><td>Name: <i>Lubna</i></td></tr> <tr><td>Since: Jan-12-2022</td></tr>   <tr><td>    </td></tr> </tbody></table> </td> <td> <table width="100%" className="list sub"> <tbody><tr><td style={{width:"50%"}}>Balance:</td><td> <b style={{color:"navy"}}>$507.59</b> </td></tr> <tr><td>Funded:</td><td> <b style={{color:"gray"}}>$0.00</b> </td></tr> <tr><td>Withdraw:</td><td> <b style={{color:"gray"}}>$0.00</b> </td></tr> <tr><td>Commission:</td><td> <b style={{color:"gray"}}>$0.00</b> </td></tr>  </tbody></table> </td> <td>  <a href="?a=user_edit" className="sbmt btn-sm btn-success" style={{marginBottom:"2px"}}>edit</a><br />   <a href="?a=user_details" className="sbmt btn-sm btn-info" style={{marginBottom: "2px"}}>funds</a> </td> </tr>


            <tr> <td colSpan="4"> </td> </tr>

            <tr id="user_133" className="row2"> <td valign="top"> <table width="100%" className="list sub"> <tbody><tr> <td> <input type="checkbox" name="users[133]" value="1" />  <b className="username">Saba</b>  <span className="badge badge-success">Active</span>   <br /> </td> </tr> <tr><td>Name: <i>Motiurrahman</i></td></tr> <tr><td>Since: Dec-9-2021</td></tr>   <tr><td>    </td></tr> </tbody></table> </td> <td> <table width="100%" className="list sub"> <tbody><tr><td style={{width:"50%"}}>Balance:</td><td> <b style={{color:"gray"}}>$0.00</b> </td></tr> <tr><td>Funded:</td><td> <b style={{color:"gray"}}>$0.00</b> </td></tr> <tr><td>Withdraw:</td><td> <b style={{color:"gray"}}>$0.00</b> </td></tr> <tr><td>Commission:</td><td> <b style={{color:"gray"}}>$0.00</b> </td></tr>  </tbody></table> </td> <td>  <a href="?a=user_edit" className="sbmt btn-sm btn-success" style={{marginBottom: "2px"}}>edit</a><br />   <a href="?a=user_details" className="sbmt btn-sm btn-info" style={{marginBottom: "2px"}}>funds</a> </td> </tr>                 */}


{mapUsers}


            </tbody>
            </table>


{/* <div style={{marginTop: "30px"}}>



            <a href="javascript:reverce()" className="sbmt btn btn-primary">Select All</a>

            <input type="button" value="Set Active" className="sbmt btn btn-success" onclick="confirm_action('set_active', 'Do you really want set Active selected users?');"></input>

            <input type="button" value="Set Disabled" className="sbmt btn btn-danger" onclick="confirm_action('set_disabled', 'Do you really want set Disabled selected users?');"></input>

            <input type="button" value="Set Suspended" className="sbmt btn btn-warning" onclick="confirm_action('set_suspended', 'Do you really want set Suspended selected users?');"></input>

            <input type="button" value="Delete" className="sbmt btn btn-danger" onclick="confirm_action('delete', 'Do you really want delete selected users?\nNON REVERSIBLE!!! ');"></input>

</div> */}
{/* 

<center> <ul className="pagination"><li className="page-item"><a className="prev page-link disabled">&lt;&lt;</a></li><li className="page-item active"><a className="page-link">1</a></li><li className="page-item"><a className="page-link" href="javascript:go(2)">2</a></li><li className="page-item"><a className="page-link" href="javascript:go(3)">3</a></li><li className="page-item"><a className="page-link" href="javascript:go(4)">4</a></li><li className="page-item"><a className="page-link" href="javascript:go(5)">5</a></li><li className="page-item"><a className="page-link" href="javascript:go(6)">6</a></li><li className="page-item"><a className="next page-link" href="javascript:go(2)">&gt;&gt;</a></li><li className="page-item"><a className="last page-link" href="javascript:go(6)">&gt;&gt;&gt;</a></li></ul> </center> */}

{/* <a className="sbmt btn btn-success" href="?a=user_edit">Add User</a> */}

<div style={{marginTop: "20px"}} className="alert alert-warning"> Members list:<br /><br /> Members list splits your members to 3 types: Active, Suspended and Disabled.<br /> Active: User can login and receive earnings if deposited in the system.<br /> Suspended: User can login, but cannot not receive any earnings from deposits.<br /> Disabled: User can not login and cannot receive any earnings.<br /> <br /> The top left search form helps you to search a user by the nickname or e-mail. You can also enter a part of a nickname or e-mail to search users.<br /> The top right form helps you to navigate between the user types.<br /> You can see the following information in the members list:<br /> Nickname, Registration date, Status, Account, Deposit, Earned, Withdrew. You can see not confirmed users also if you use double opt-in registration.<br /> <br /> Edit user information: click on the "Edit" button next to a user<br /> Delete user: click on the "delete" button and confirm this action;<br /> Send e-mail to user: click on the "e-mail" button and send e-mail to user.<br /> "Manage funds" button will help you to check any user's history and change his funds.<br /> Add a new Member: click on the "Add a new member&amp; button. You&amp;ll see the form for adding a new member. </div>


            </form>
            </td>
             </tr>
      </tbody>
    </table>
  );
};

export default User;
