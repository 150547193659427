import React from 'react';

const TopReferrial = () => {
  return (
    <table
      width="100%"
      height="100%"
      border="0"
      cellpadding="10"
      cellspacing="0"
      className="forTexts"
    >
      {' '}
      <tbody>
        <tr>
          {' '}
          <td width="100%" height="100%" valign="top">
            {' '}
            <h3>Top Referral Earnings:</h3>{' '}
            <form method="post" name="trans">
              <table className="form nosize">
                {' '}
                <tbody>
                  <tr>
                    {' '}
                    <td>
                      {' '}
                      <select name="ec" className="inpts">
                        {' '}
                        <option value="-1">All eCurrencies</option>{' '}
                        <option value="48">Bitcoin</option>{' '}
                        <option value="69">Ethereum</option>{' '}
                        <option value="1002">Ethereum</option>{' '}
                        <option value="1000">Bitcoin</option>{' '}
                      </select>{' '}
                      <br />
                      <input
                        type="text"
                        name="onpage"
                        value="20"
                        className="inpts nosize"
                        size="4"
                        style={{textAlign: 'right'}}
                      />{' '}
                      top members{' '}
                    </td>{' '}
                    <td style={{textAlign: 'right'}}>
                      {' '}
                      From:{' '}
                      <select name="from_Day" className="inpts nosize">
                        <option value="1" selected="selected">
                          01
                        </option>
                        <option value="2">02</option>
                        <option value="3">03</option>
                        <option value="4">04</option>
                        <option value="5">05</option>
                        <option value="6">06</option>
                        <option value="7">07</option>
                        <option value="8">08</option>
                        <option value="9">09</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                        <option value="15">15</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                        <option value="21">21</option>
                        <option value="22">22</option>
                        <option value="23">23</option>
                        <option value="24">24</option>
                        <option value="25">25</option>
                        <option value="26">26</option>
                        <option value="27">27</option>
                        <option value="28">28</option>
                        <option value="29">29</option>
                        <option value="30">30</option>
                        <option value="31">31</option>
                      </select>
                      <select name="from_Month" className="inpts nosize">
                        <option value="01" selected="selected">
                          01
                        </option>
                        <option value="02">02</option>
                        <option value="03">03</option>
                        <option value="04">04</option>
                        <option value="05">05</option>
                        <option value="06">06</option>
                        <option value="07">07</option>
                        <option value="08">08</option>
                        <option value="09">09</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                      </select>
                      <select name="from_Year" className="inpts nosize">
                        <option value="2011">2011</option>
                        <option value="2012">2012</option>
                        <option value="2013">2013</option>
                        <option value="2014">2014</option>
                        <option value="2015">2015</option>
                        <option value="2016">2016</option>
                        <option value="2017">2017</option>
                        <option value="2018">2018</option>
                        <option value="2019">2019</option>
                        <option value="2020">2020</option>
                        <option value="2021">2021</option>
                        <option value="2022" selected="selected">
                          2022
                        </option>
                      </select>{' '}
                      <br /> To:{' '}
                      <select name="to_Day" className="inpts nosize">
                        <option value="1">01</option>
                        <option value="2">02</option>
                        <option value="3">03</option>
                        <option value="4">04</option>
                        <option value="5">05</option>
                        <option value="6">06</option>
                        <option value="7">07</option>
                        <option value="8">08</option>
                        <option value="9">09</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13" selected="selected">
                          13
                        </option>
                        <option value="14">14</option>
                        <option value="15">15</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                        <option value="21">21</option>
                        <option value="22">22</option>
                        <option value="23">23</option>
                        <option value="24">24</option>
                        <option value="25">25</option>
                        <option value="26">26</option>
                        <option value="27">27</option>
                        <option value="28">28</option>
                        <option value="29">29</option>
                        <option value="30">30</option>
                        <option value="31">31</option>
                      </select>
                      <select name="to_Month" className="inpts nosize">
                        <option value="01" selected="selected">
                          01
                        </option>
                        <option value="02">02</option>
                        <option value="03">03</option>
                        <option value="04">04</option>
                        <option value="05">05</option>
                        <option value="06">06</option>
                        <option value="07">07</option>
                        <option value="08">08</option>
                        <option value="09">09</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                      </select>
                      <select name="to_Year" className="inpts nosize">
                        <option value="2011">2011</option>
                        <option value="2012">2012</option>
                        <option value="2013">2013</option>
                        <option value="2014">2014</option>
                        <option value="2015">2015</option>
                        <option value="2016">2016</option>
                        <option value="2017">2017</option>
                        <option value="2018">2018</option>
                        <option value="2019">2019</option>
                        <option value="2020">2020</option>
                        <option value="2021">2021</option>
                        <option value="2022" selected="selected">
                          2022
                        </option>
                      </select>{' '}
                    </td>{' '}
                    <td>
                      {' '}
                      <input type="submit" value="Go" className="sbmt" />{' '}
                    </td>{' '}
                  </tr>{' '}
                </tbody>
              </table>{' '}
            </form>{' '}
            <br />
            <br />{' '}
            <table className="list">
              {' '}
              <tbody>
                <tr>
                  {' '}
                  <th>Username</th> <th>Amount</th>{' '}
                </tr>{' '}
                <tr className="row1">
                  {' '}
                  <td>Jack</td>{' '}
                  <td width="200" align="right">
                    <b>$360.20</b>
                  </td>{' '}
                </tr>{' '}
              </tbody>
            </table>{' '}
          </td>{' '}
        </tr>{' '}
      </tbody>
    </table>
  );
};

export default TopReferrial;
