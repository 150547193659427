import React from 'react'

const NewsLetter = () => {
    return (
        <table width="100%" height="100%" border="0" class="forTexts"> <tbody><tr> <td width="100%" height="100%" valign="top">  <h3>Send a newsletter to users:</h3>  
        
         <form method="post" onsubmit="return checkform();" name="formb">
              <table class="form"> <tbody><tr> <th>Being sent to:</th> <td> <select name="to" class="inpts"> <option value="user">One user (type a username below) </option><option value="all">All users </option><option value="active">All users which have made a deposit </option><option value="passive">All users which haven't made a deposit  </option><option value="plan_1">All users which have made a deposit to 8% after 24 hours</option>  <option value="plan_2">All users which have made a deposit to 16% after 52 hours</option>  <option value="plan_3">All users which have made a deposit to 24% after 4 days</option>   </select> </td> </tr> <tr> <th>Username:</th> <td>
                  <input type="text" name="username" value="" class="inpts" size="30" /></td> </tr> <tr> <th>Subject:</th> <td>
                      <input type="text" name="subject" value="" class="inpts" /></td> </tr> <tr> <th>Use Presets?</th> <td> <select name="use_presets" class="inpts"> <option value="1">Yes</option> <option value="0" selected="">No</option> </select> </td> </tr> <tr> <th>Text Message:</th> <td><textarea name="text" class="inpts" rows="10"></textarea></td> </tr> <tr> <th>HTML Message:<br />
                      <input type="checkbox" name="use_html" value="1" /> Use it?</th> <td> <textarea name="html" class="inpts " rows="10"></textarea> </td> </tr> <tr> <th></th> <td>
                          <input type="submit" value="Send Newsletter" class="sbmt"  /> 
                          <input type="button" value="Send Test E-mail" class="sbmt" /></td> </tr> </tbody></table> </form> <div class="alert alert-warning"> Send a newsletter:<br /> This form helps you to send a newsletter to one or several users.<br /> Select a user or a user group, type a subject and a message text. Click on the 'send newsletter' button once! It may take a time for a huge list.<br /><br /> Personalization:<br /> You can use the following variables to personalize the newsletter:<br /> #name# - user's first and last name<br /> #username# - user's login<br /> #email# - user's e-mail address<br /> #date_register# - user's registration date<br /> </div>  </td> </tr> </tbody></table>
    )
}

export default NewsLetter
