import React from 'react'

const LinkReplacement = () => {
    return (
        <table width="100%" height="100%" border="0" className="forTexts"> <tbody><tr> <td width="100%" height="100%"> 
         <h3>SEO Links Replacement:</h3> <div className="alert alert-warning"> Before enable this option be sure you have configure your web server correctly.<br /> For Apache web server you should place these lines to .htaccess file in the script root folder: <div style={{border:"dotted 1px black", padding:"3px", margin: "3px", background:"#fff"}}> RewriteEngine on <br /> RewriteCond %  -f [OR]<br /> RewriteCond % -d <br /> RewriteRule ^.*$ - [NC,L]<br /> RewriteRule .* index.php [L]<br /> </div> Be aware to replace an action code with other action code. It will makes first action unavailable. </div> <br /> <form method="post"> <table className="form nosize"> <tbody><tr> <th>Use SEO Links:</th> <td> <select name="seo_links" className="inpts nosize" style={{width:"200px"}}> <option value="0" selected="">No</option> <option value="1">Yes</option> </select>
          <input type="submit" value="Save" className="sbmt" /> </td> </tr> </tbody></table> </form> <br /><br /> 
          <h3>Actions:</h3> <form method="post" onsubmit="return check_actions()"> <table className="form"> <tbody><tr> <th>Default</th> <th style={{textAlign: "left"}}>Rename</th> </tr>  <tr> <th id="def1">home</th> <td>
              <input type="text" name="actions[home]" value="" className="inpts" size="30" id="val1" /></td> </tr>  <tr> <th id="def2">signup</th> <td>
                  <input type="text" name="actions[signup]" value="" className="inpts" size="30" id="val2" /></td> </tr>  <tr> <th id="def3">login</th> <td>
                      <input type="text" name="actions[login]" value="" className="inpts" size="30" id="val3" /></td> </tr>  <tr> <th id="def4">logout</th> <td>
                          <input type="text" name="actions[logout]" value="" className="inpts" size="30" id="val4" /></td> </tr>  <tr> <th id="def5">forgot_password</th> <td>
                              <input type="text" name="actions[forgot_password]" value="" className="inpts" size="30" id="val5" /></td> </tr>  <tr> <th id="def6">account</th> <td>
                                  <input type="text" name="actions[account]" value="" className="inpts" size="30" id="val6" /></td> </tr>  <tr> <th id="def7">deposit</th> <td>
                                      <input type="text" name="actions[deposit]" value="" className="inpts" size="30" id="val7" /></td> </tr>  <tr> <th id="def8">add_funds</th> <td>
                                          <input type="text" name="actions[add_funds]" value="" className="inpts" size="30" id="val8" /></td> </tr>  <tr> <th id="def9">withdraw</th> <td>
                                              <input type="text" name="actions[withdraw]" value="" className="inpts" size="30" id="val9" /></td> </tr>  <tr> <th id="def10">history</th> <td>
                                                  
                                                  <input type="text" name="actions[history]" value="" className="inpts" size="30" id="val10" />
                                                  
                                                  </td> </tr>  <tr> <th id="def11">deposit_list</th> <td>
                                                      <input type="text" name="actions[deposit_list]" value="" className="inpts" size="30" id="val11" />
                                                      
                                                      </td> </tr>  <tr> <th id="def12">change_compound</th> <td>
                                                      <input type="text" name="actions[change_compound]" value="" className="inpts" size="30" id="val12" />
                                                      
                                                      </td> </tr>  <tr> <th id="def13">withdraw_principal</th> <td>
                                                      <input type="text" name="actions[withdraw_principal]" value="" className="inpts" size="30" id="val13" />
                                                      
                                                      </td> </tr>  <tr> <th id="def14">internal_transfer</th> <td>
                                                      <input type="text" name="actions[internal_transfer]" value="" className="inpts" size="30" id="val14" />
                                                      
                                                      </td> </tr>  <tr> <th id="def15">exchange</th> <td>
                                                      <input type="text" name="actions[exchange]" value="" className="inpts" size="30" id="val15" />
                                                      
                                                      </td> </tr>  <tr> <th id="def16">tell_friend</th> <td>
                                                      <input type="text" name="actions[tell_friend]" value="" className="inpts" size="30" id="val16" />
                                                      
                                                      </td> </tr>  <tr> <th id="def17">edit_account</th> <td>
                                                      <input type="text" name="actions[edit_account]" value="" className="inpts" size="30" id="val17" />
                                                      
                                                      </td> </tr>  <tr> <th id="def18">security</th> <td>
                                                      <input type="text" name="actions[security]" value="" className="inpts" size="30" id="val18" />
                                                      
                                                      </td> </tr>  <tr> <th id="def19">trans_code_recovery</th> <td>
                                                      <input type="text" name="actions[trans_code_recovery]" value="" className="inpts" size="30" id="val19" />
                                                      
                                                      </td> </tr>  <tr> <th id="def20">cancelwithdraw</th> <td>
                                                      <input type="text" name="actions[cancelwithdraw]" value="" className="inpts" size="30" id="val20" />
                                                      
                                                      </td> </tr>  <tr> <th id="def21">referallinks</th> <td>
                                                      <input type="text" name="actions[referallinks]" value="" className="inpts" size="30" id="val21" />
                                                      
                                                      </td> </tr>  <tr> <th id="def22">referals</th> <td>
                                                      <input type="text" name="actions[referals]" value="" className="inpts" size="30" id="val22" />
                                                      
                                                      </td> </tr>  <tr> <th id="def23">support</th> <td>
                                                      <input type="text" name="actions[support]" value="" className="inpts" size="30" id="val23" />
                                                      
                                                      </td> </tr>  <tr> <th id="def24">faq</th> <td>
                                                      <input type="text" name="actions[faq]" value="" className="inpts" size="30" id="val24" />
                                                      
                                                      </td> </tr>  <tr> <th id="def25">rules</th> <td>
                                                      <input type="text" name="actions[rules]" value="" className="inpts" size="30" id="val25" />
                                                      
                                                      </td> </tr>  <tr> <th id="def26">members_stats</th> <td>
                                                      <input type="text" name="actions[members_stats]" value="" className="inpts" size="30" id="val26" />
                                                      
                                                      </td> </tr>  <tr> <th id="def27">paidout</th> <td>
                                                      <input type="text" name="actions[paidout]" value="" className="inpts" size="30" id="val27" />
                                                      
                                                      </td> </tr>  <tr> <th id="def28">top10</th> <td>
                                                      <input type="text" name="actions[top10]" value="" className="inpts" size="30" id="val28" />
                                                      
                                                      </td> </tr>  <tr> <th id="def29">last10</th> <td>
                                                      <input type="text" name="actions[last10]" value="" className="inpts" size="30" id="val29" />
                                                      
                                                      </td> </tr>  <tr> <th id="def30">refs10</th> <td>
                                                      <input type="text" name="actions[refs10]" value="" className="inpts" size="30" id="val30" />
                                                      
                                                      </td> </tr>  <tr> <th id="def31">return_egold</th> <td>
                                                      <input type="text" name="actions[return_egold]" value="" className="inpts" size="30" id="val31" />
                                                      
                                                      </td> </tr>  <tr> <th id="def32">return_success</th> <td>
                                                      <input type="text" name="actions[return_success]" value="" className="inpts" size="30" id="val32" />
                                                      
                                                      </td> </tr>  <tr> <th id="def33">return_fails</th> <td>
                                                      <input type="text" name="actions[return_fails]" value="" className="inpts" size="30" id="val33" />
                                                      
                                                      </td> </tr>  <tr> <th id="def34">review</th> <td>
                                                      <input type="text" name="actions[review]" value="" className="inpts" size="30" id="val34" />
                                                      
                                                      </td> </tr>  <tr> <th id="def35">news</th> <td>
                                                      <input type="text" name="actions[news]" value="" className="inpts" size="30" id="val35" />
                                                      
                                                      </td> </tr>  <tr> <th id="def36">calendar</th> <td>
                                                      <input type="text" name="actions[calendar]" value="" className="inpts" size="30" id="val36" />
                                                      
                                                      </td> </tr>  <tr> <th id="def37">activate</th> <td>
                                                      <input type="text" name="actions[activate]" value="" className="inpts" size="30" id="val37" />
                                                      
                                                      </td> </tr>  <tr> <th id="def38">show_package_info</th> <td>
                                                      <input type="text" name="actions[show_package_info]" value="" className="inpts" size="30" id="val38" />
                                                      
                                                      </td> </tr>  <tr> <th id="def39">ref_plans</th> <td>
                                                      <input type="text" name="actions[ref_plans]" value="" className="inpts" size="30" id="val39" />
                                                      
                                                      </td> </tr>  <tr> <th id="def40">rateus</th> <td>
                                                      <input type="text" name="actions[rateus]" value="" className="inpts" size="30" id="val40" />
                                                      
                                                      </td> </tr>  <tr> <th id="def41">cust</th> <td>
                                                      <input type="text" name="actions[cust]" value="" className="inpts" size="30" id="val41" />
                                                      
                                                      </td> </tr>  <tr> <th id="def42">show_validation_image</th> <td>
                                                      <input type="text" name="actions[show_validation_image]" value="" className="inpts" size="30" id="val42" />
                                                      
                                                      </td> </tr>  <tr> <th id="def43">ref</th> <td>
                                                      <input type="text" name="actions[ref]" value="" className="inpts" size="30" id="val43" />
                                                      
                                                      </td> </tr>  <tr> <th></th> <td><input type="submit" value="Save" className="sbmt" /></td> </tr> </tbody></table> </form> <div className="alert alert-warning"> Here you could change names of actions links.<br /> </div>  </td> </tr> </tbody></table>
    )
}

export default LinkReplacement
