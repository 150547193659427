import React from 'react'

const EditEmailTemplate = () => {
    return (
        <table width="100%" height="100%" border="0" cellpadding="10" cellspacing="0" class="forTexts"> <tbody><tr> <td width="100%" height="100%" valign="top">  <h3>Edit Email Template "Bonus Notification"</h3>     
        
        
         <form method="post" name="edit_email">
             
              <table class="form"> <tbody><tr> <th>Subject:</th> <td><input type="text" name="subject" value="Bonus Notification" class="inpts" size="100" /></td> </tr> <tr> <th>Use Presets?</th> <td> <select name="use_presets" class="inpts"> <option value="1" selected="">Yes</option> <option value="0">No</option> </select> </td> </tr> <tr> <th>Text Message:</th> <td><textarea name="text" cols="100" rows="20" class="inpts">Hello #name#,

You received a bonus: $#amount#
You can check your statistics here:
#site_url#

Good luck.</textarea></td> </tr> <tr> <th>HTML Message:<br /> <input type="checkbox" name="use_html" value="1" /> Use it?</th> <td><textarea name="html" class="inpts" cols="100" rows="20"></textarea></td> </tr> <tr> <th></th> <td><input type="submit" value="Update" class="btn-sm btn-success sbmt" /> <input type="button" value="Send Test E-mail" class="sbmt"  /> <a class="sbmt btn-sm btn-danger" href="?a=email_templates">Cancel</a></td> </tr> </tbody></table> </form> <div class="alert alert-warning">      Users will receive this e-mail if admin add deposit to they account and select checkbox "send notification".<br /><br /> Personalization:<br /> #name# - first and last user name.<br /> #amount# - bonus amount<br /> #site_url# - your site url (check settings screen to set this variable)<br /> #site_name# - your site name (check settings screen to set this variable)<br />          </div>  </td> </tr> </tbody></table>
    )
}

export default EditEmailTemplate
