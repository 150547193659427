import React from 'react'

const Mentenance = () => {
    return (
        <table width="100%" height="100%" border="0" class="forTexts"> <tbody><tr> <td width="100%" height="100%" valign="top">  <h3>Maintenance Page:</h3>    <form method="post">
            
            <table class="form"> <tbody><tr> <th>Enable Maintenance Page:</th> <td> <select name="enabled" class="inpts"> <option value="0" selected="">No</option> <option value="1">Yes</option> </select> </td> </tr> <tr> <th>Skip Maintenance Page Pin:</th> <td> <input type="text" name="pin" class="inpts" value="Update@#£_" /> </td></tr> <tr> <th>Maintenance Page Message:</th> <td> <textarea name="message" class="inpts" rows="10">We are currently on maintenance we will be back online as soon as the maintenance is completed.</textarea> </td></tr> <tr> <th></th> <td><input type="submit" value="Save" class="sbmt" /></td> </tr> </tbody></table> </form> <div class="alert alert-warning"> If you need close your site for maintenance you can enable this page.<br /> User will see your Maintenance Message and can't open site.<br /> Maintenance Pin can be used to allow you skip maintenance page and enter site if you need.<br /> </div>  </td> </tr> </tbody></table>
    )
}

export default Mentenance
