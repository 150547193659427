import React from 'react'

const EmailTemplate = () => {
    return (
       <table width="100%" height="100%" border="0" class="forTexts"> <tbody><tr> <td width="100%" height="100%" valign="top">   <h3>Edit E-mail Templates:</h3> <form method="post">
           
            <table class="list">  <tbody><tr class="row1"> <td>
                <input type="checkbox" name="emails[registration]" value="1" checked="" /></td> <td>Registration Completetion</td> <td><a href="?a=email_templates&amp;action=edit&amp;type=registration" class="sbmt btn-sm btn-success">edit</a></td> </tr>  <tr class="row2"> <td>
                <input type="checkbox" name="emails[confirm_registration]" value="1" checked="" /></td> <td>Registration Confirmation</td> <td><a href="?a=email_templates&amp;action=edit&amp;type=confirm_registration" class="sbmt btn-sm btn-success">edit</a></td> </tr>  <tr class="row1"> <td>
                <input type="checkbox" name="emails[forgot_password]" value="1" checked="" /></td> <td>Password Reminder</td> <td><a href="?a=email_templates&amp;action=edit&amp;type=forgot_password" class="sbmt btn-sm btn-success">edit</a></td> </tr>  <tr class="row2"> <td>
                <input type="checkbox" name="emails[forgot_password_confirm]" value="1" checked="" /></td> <td>Password Reminder Confirmation</td> <td><a href="?a=email_templates&amp;action=edit&amp;type=forgot_password_confirm" class="sbmt btn-sm btn-success">edit</a></td> </tr>  <tr class="row1"> <td>
                <input type="checkbox" name="emails[bonus]" value="1" checked="" /></td> <td>Bonus Notification</td> <td><a href="?a=email_templates&amp;action=edit&amp;type=bonus" class="sbmt btn-sm btn-success">edit</a></td> </tr>  <tr class="row2"> <td>
                <input type="checkbox" name="emails[penalty]" value="1" checked="" /></td> <td>Penalty Notification</td> <td><a href="?a=email_templates&amp;action=edit&amp;type=penalty" class="sbmt btn-sm btn-success">edit</a></td> </tr>  <tr class="row1"> <td>
                <input type="checkbox" name="emails[change_account]" value="1" checked="" /></td> <td>Account Change Notification</td> <td><a href="?a=email_templates&amp;action=edit&amp;type=change_account" class="sbmt btn-sm btn-success">edit</a></td> </tr>  <tr class="row2"> <td>
                <input type="checkbox" name="emails[withdraw_request_user_notification]" value="1" checked="" /></td> <td>User Withdrawal Request Notification</td> <td><a href="?a=email_templates&amp;action=edit&amp;type=withdraw_request_user_notification" class="sbmt btn-sm btn-success">edit</a></td> </tr>  <tr class="row1"> <td>
                <input type="checkbox" name="emails[withdraw_request_admin_notification]" value="1" checked="" /></td> <td>Administrator Withdrawal Request Notification</td> <td><a href="?a=email_templates&amp;action=edit&amp;type=withdraw_request_admin_notification" class="sbmt btn-sm btn-success">edit</a></td> </tr>  <tr class="row2"> <td>
                <input type="checkbox" name="emails[withdraw_user_notification]" value="1" checked="" /></td> <td>User Withdrawal Notification</td> <td><a href="?a=email_templates&amp;action=edit&amp;type=withdraw_user_notification" class="sbmt btn-sm btn-success">edit</a></td> </tr>  <tr class="row1"> <td>
                <input type="checkbox" name="emails[withdraw_admin_notification]" value="1" checked="" /></td> <td>Administrator Withdrawal Notification</td> <td><a href="?a=email_templates&amp;action=edit&amp;type=withdraw_admin_notification" class="sbmt btn-sm btn-success">edit</a></td> </tr>  <tr class="row2"> <td>
                <input type="checkbox" name="emails[deposit_admin_notification]" value="1" checked="" /></td> <td>Administrator Deposit Notification</td> <td><a href="?a=email_templates&amp;action=edit&amp;type=deposit_admin_notification" class="sbmt btn-sm btn-success">edit</a></td> </tr>  <tr class="row1"> <td>
                <input type="checkbox" name="emails[deposit_user_notification]" value="1" checked="" /></td> <td>Deposit User Notification</td> <td><a href="?a=email_templates&amp;action=edit&amp;type=deposit_user_notification" class="sbmt btn-sm btn-success">edit</a></td> </tr>  <tr class="row2"> <td>
                <input type="checkbox" name="emails[exchange_admin_notification]" value="1" /></td> <td>Exchange Admin Notification</td> <td><a href="?a=email_templates&amp;action=edit&amp;type=exchange_admin_notification" class="sbmt btn-sm btn-success">edit</a></td> </tr>  <tr class="row1"> <td>
                <input type="checkbox" name="emails[exchange_user_notification]" value="1" /></td> <td>Exchange User Notification</td> <td><a href="?a=email_templates&amp;action=edit&amp;type=exchange_user_notification" class="sbmt btn-sm btn-success">edit</a></td> </tr>  <tr class="row2"> <td>
                <input type="checkbox" name="emails[brute_force_activation]" value="1" checked="" /></td> <td>Account Activation after Brute Force</td> <td><a href="?a=email_templates&amp;action=edit&amp;type=brute_force_activation" class="sbmt btn-sm btn-success">edit</a></td> </tr>  <tr class="row1"> <td>
                <input type="checkbox" name="emails[direct_signup_notification]" value="1" checked="" /></td> <td>Direct Referral Signup</td> <td><a href="?a=email_templates&amp;action=edit&amp;type=direct_signup_notification" class="sbmt btn-sm btn-success">edit</a></td> </tr>  <tr class="row2"> <td>
                <input type="checkbox" name="emails[referral_commision_notification]" value="1" checked="" /></td> <td>Referral Comission Notification</td> <td><a href="?a=email_templates&amp;action=edit&amp;type=referral_commision_notification" class="sbmt btn-sm btn-success">edit</a></td> </tr>  <tr class="row1"> <td>
                <input type="checkbox" name="emails[pending_deposit_admin_notification]" value="1" checked="" /></td> <td>Deposit Request Admin Notification</td> <td><a href="?a=email_templates&amp;action=edit&amp;type=pending_deposit_admin_notification" class="sbmt btn-sm btn-success">edit</a></td> </tr>  <tr class="row2"> <td>
                <input type="checkbox" name="emails[deposit_approved_admin_notification]" value="1" checked="" /></td> <td>Deposit Approved Admin Notification</td> <td><a href="?a=email_templates&amp;action=edit&amp;type=deposit_approved_admin_notification" class="sbmt btn-sm btn-success">edit</a></td> </tr>  <tr class="row1"> <td>
                <input type="checkbox" name="emails[deposit_approved_user_notification]" value="1" checked="" /></td> <td>Deposit Approved User Notification</td> <td><a href="?a=email_templates&amp;action=edit&amp;type=deposit_approved_user_notification" class="sbmt btn-sm btn-success">edit</a></td> </tr>  <tr class="row2"> <td>
                <input type="checkbox" name="emails[account_update_confirmation]" value="1" checked="" /></td> <td>Account Update Confirmation</td> <td><a href="?a=email_templates&amp;action=edit&amp;type=account_update_confirmation" class="sbmt btn-sm btn-success">edit</a></td> </tr>  <tr class="row1"> <td>
                <input type="checkbox" name="emails[acsent_user]" value="1" checked="" /></td> <td>Send pin to user</td> <td><a href="?a=email_templates&amp;action=edit&amp;type=acsent_user" class="sbmt btn-sm btn-success">edit</a></td> </tr>  <tr class="row2"> <td>
                <input type="checkbox" name="emails[transaction_code_recovery]" value="1" checked="" /></td> <td>Transaction code recovery</td> <td><a href="?a=email_templates&amp;action=edit&amp;type=transaction_code_recovery" class="sbmt btn-sm btn-success">edit</a></td> </tr>  <tr class="row1"> <td>
                <input type="checkbox" name="emails[tell_a_friend]" value="1" checked="" /></td> <td>Tell a friend</td> <td><a href="?a=email_templates&amp;action=edit&amp;type=tell_a_friend" class="sbmt btn-sm btn-success">edit</a></td> </tr>  <tr class="row2"> <td>
                <input type="checkbox" name="emails[deposit_account_admin_notification]" value="1" checked="" /></td> <td>Administrator Deposit Notification (from account balance)</td> <td><a href="?a=email_templates&amp;action=edit&amp;type=deposit_account_admin_notification" class="sbmt btn-sm btn-success">edit</a></td> </tr>  <tr class="row1"> <td>
                <input type="checkbox" name="emails[user_deposit_expired]" value="1" /></td> <td>Deposit expired to user</td> <td><a href="?a=email_templates&amp;action=edit&amp;type=user_deposit_expired" class="sbmt btn-sm btn-success">edit</a></td> </tr>  <tr class="row2"> <td>
                <input type="checkbox" name="emails[2fa_disabled_notification]" value="1" checked="" /></td> <td>2FA Disable User Notification</td> <td><a href="?a=email_templates&amp;action=edit&amp;type=2fa_disabled_notification" class="sbmt btn-sm btn-success">edit</a></td> </tr>  <tr class="row1"> <td>
                <input type="checkbox" name="emails[2fa_enabled_notification]" value="1" checked="" /></td> <td>2FA Enable User Notification</td> <td><a href="?a=email_templates&amp;action=edit&amp;type=2fa_enabled_notification" class="sbmt btn-sm btn-success">edit</a></td> </tr>  <tr> <td colspan="2">
                
                <input type="submit" value="Update" class="sbmt" /></td> </tr> </tbody></table> </form> <h3>Template Presets</h3> <form method="post">
                
                 <table class="form"> <tbody><tr> <th>Text Header:</th> <td><textarea name="text_header" class="inpts" cols="100" rows="20"></textarea></td> </tr> <tr> <th>Text Footer:</th> <td><textarea name="text_footer" class="inpts" cols="100" rows="20"></textarea></td> </tr> <tr> <th>HTML Header:</th> <td><textarea name="html_header" class="inpts" cols="100" rows="20"></textarea></td> </tr> <tr> <th>HTML Footer:</th> <td><textarea name="html_footer" class="inpts" cols="100" rows="20"></textarea></td> </tr> <tr> <th></th> <td><input type="submit" value="Update" class="sbmt" /></td> </tr> </tbody></table> </form>  </td> </tr> </tbody></table>
    )
}

export default EmailTemplate
