import React from 'react'

const News = () => {
    return (
        <table width="100%" height="100%" border="0" class="forTexts"> <tbody><tr> <td width="100%" height="100%" valign="top">    <form name="filters" method="get">
            
            <table class="form"> <tbody><tr> <th>News Box</th> <td> <select name="show_news_box" class="inpts"> <option value="0" selected="">Hide</option> <option value="1">Show</option> </select> </td> <td rowspan="2"> <input type="submit" value="Save" class="sbmt" /> </td> </tr> <tr> <th>News Box Items Number</th> <td><input type="text" name="last_news_count" value="5" class="inpts" /></td> </tr> </tbody></table> </form>  <h3>News:  <a href="?a=news&amp;action=edit" class="sbmt btn-sm btn-success">Add News</a>  </h3>  <div class="alert alert-warning"> Here you can manage your program news.<br /> Your newly added news will appear on your site index page (if you have enabled "e;Show news box in InfoBox Settings section"e;)<br /> Small text will appear on Index page. If you omit Small Text then the system will show first 100-120 characters of your Full Text.<br /> If you omit Full Text than the system will show Small Text on all the news page. </div>  </td> </tr> </tbody></table>
    )
}

export default News
