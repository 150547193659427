import React from 'react';
import BTC from '../../btc.gif';
import LITE from '../../litecoin.gif';
import ETH from '../../ethereum.gif';
import GOLD from '../../goldMoney.gif';
import NET from '../../netPay.gif';

const ExchangeRate = () => {
  return (
    <table
      width="100%"
      height="100%"
      border="0"
      className="forTexts"
    >
      <tbody>
        <tr>
          <td width="100%" height="100%" valign="top">
            <h3>Exchange Rates:</h3>
            <form method="post" name="main">
              <input type="checkbox" name="enable_exchange" value="1" />
                Enable exchange section
                <br />
                <br />
                <table cellspacing="0" cellpadding="0" border="0">
                  <tbody>
                    <tr>
                      <th valign="top" className="title">
                        <table cellspacing="1" cellpadding="2" border="0">
                          <tbody>
                            <tr>
                              <td bgcolor="#FFFFFF" nowrap="" align="center">
                                From / To
                              </td>
                              <td bgcolor="#FFFFFF" align="center">
                                <img src={BTC} height="17" />
                              </td>
                              <td bgcolor="#FFFFFF" align="center">
                                <img src={LITE} height="17" />
                              </td>
                              <td bgcolor="#FFFFFF" align="center">
                                <img src={ETH} height="17" />
                              </td>
                              <td bgcolor="#FFFFFF" align="center">
                                <img src={GOLD} height="17" />
                              </td>
                              <td bgcolor="#FFFFFF" align="center">
                                <img src={BTC} height="17" />
                              </td>
                              <td bgcolor="#FFFFFF" align="center">
                                <img src={NET} height="17" />
                              </td>
                              <td bgcolor="#FFFFFF" align="center">
                                Set Row
                              </td>
                            </tr>
                            <tr>
                              <td align="center" bgcolor="#FFFFFF">
                                <img src={BTC} height="17" />
                              </td>
                              <td align="center" bgcolor="#FFFFFF">
                                N/A
                              </td>
                              <td align="center" bgcolor="#FFFFFF">
                                <input
                                  type="text"
                                  name="exch[48][68]"
                                  value="0.00"
                                  size="5"
                                  className="inpts"
                                />
                              </td>
                              <td align="center" bgcolor="#FFFFFF">
                                <input
                                  type="text"
                                  name="exch[48][69]"
                                  value="0.00"
                                  size="5"
                                  className="inpts"
                                />
                              </td>
                              <td align="center" bgcolor="#FFFFFF">
                                <input
                                  type="text"
                                  name="exch[48][1002]"
                                  value="0.00"
                                  size="5"
                                  className="inpts"
                                />
                              </td>
                              <td align="center" bgcolor="#FFFFFF">
                                <input
                                  type="text"
                                  name="exch[48][1000]"
                                  value="0.00"
                                  size="5"
                                  className="inpts"
                                />
                              </td>
                              <td align="center" bgcolor="#FFFFFF">
                                <input
                                  type="text"
                                  name="exch[48][1001]"
                                  value="0.00"
                                  size="5"
                                  className="inpts"
                                />
                              </td>
                              <td align="center" bgcolor="#FFFFFF">
                                <input
                                  type="text"
                                  name="set_row_48"
                                  value=""
                                  size="5"
                                  className="inpts"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td align="center" bgcolor="#FFFFFF">
                                <img src={LITE} height="17" />
                              </td>
                              <td align="center" bgcolor="#FFFFFF">
                                <input
                                  type="text"
                                  name="exch[68][48]"
                                  value="0.00"
                                  size="5"
                                  className="inpts"
                                />
                              </td>
                              <td align="center" bgcolor="#FFFFFF">
                                N/A
                              </td>
                              <td align="center" bgcolor="#FFFFFF">
                                <input
                                  type="text"
                                  name="exch[68][69]"
                                  value="0.00"
                                  size="5"
                                  className="inpts"
                                />
                              </td>
                              <td align="center" bgcolor="#FFFFFF">
                                <input
                                  type="text"
                                  name="exch[68][1002]"
                                  value="0.00"
                                  size="5"
                                  className="inpts"
                                />
                              </td>
                              <td align="center" bgcolor="#FFFFFF">
                                <input
                                  type="text"
                                  name="exch[68][1000]"
                                  value="0.00"
                                  size="5"
                                  className="inpts"
                                />
                              </td>
                              <td align="center" bgcolor="#FFFFFF">
                                <input
                                  type="text"
                                  name="exch[68][1001]"
                                  value="0.00"
                                  size="5"
                                  className="inpts"
                                />
                              </td>
                              <td align="center" bgcolor="#FFFFFF">
                                <input
                                  type="text"
                                  name="set_row_68"
                                  value=""
                                  size="5"
                                  className="inpts"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td align="center" bgcolor="#FFFFFF">
                                <img src={ETH} height="17" />
                              </td>
                              <td align="center" bgcolor="#FFFFFF">
                                <input
                                  type="text"
                                  name="exch[69][48]"
                                  value="0.00"
                                  size="5"
                                  className="inpts"
                                />
                              </td>
                              <td align="center" bgcolor="#FFFFFF">
                                <input
                                  type="text"
                                  name="exch[69][68]"
                                  value="0.00"
                                  size="5"
                                  className="inpts"
                                />
                              </td>
                              <td align="center" bgcolor="#FFFFFF">
                                N/A
                              </td>
                              <td align="center" bgcolor="#FFFFFF">
                                <input
                                  type="text"
                                  name="exch[69][1002]"
                                  value="0.00"
                                  size="5"
                                  className="inpts"
                                />
                              </td>
                              <td align="center" bgcolor="#FFFFFF">
                                <input
                                  type="text"
                                  name="exch[69][1000]"
                                  value="0.00"
                                  size="5"
                                  className="inpts"
                                />
                              </td>
                              <td align="center" bgcolor="#FFFFFF">
                                <input
                                  type="text"
                                  name="exch[69][1001]"
                                  value="0.00"
                                  size="5"
                                  className="inpts"
                                />
                              </td>
                              <td align="center" bgcolor="#FFFFFF">
                                <input
                                  type="text"
                                  name="set_row_69"
                                  value=""
                                  size="5"
                                  className="inpts"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td align="center" bgcolor="#FFFFFF">
                                <img src={GOLD} height="17" />
                              </td>
                              <td align="center" bgcolor="#FFFFFF">
                                <input
                                  type="text"
                                  name="exch[1002][48]"
                                  value="0.00"
                                  size="5"
                                  className="inpts"
                                />
                              </td>
                              <td align="center" bgcolor="#FFFFFF">
                                <input
                                  type="text"
                                  name="exch[1002][68]"
                                  value="0.00"
                                  size="5"
                                  className="inpts"
                                />
                              </td>
                              <td align="center" bgcolor="#FFFFFF">
                                <input
                                  type="text"
                                  name="exch[1002][69]"
                                  value="0.00"
                                  size="5"
                                  className="inpts"
                                />
                              </td>
                              <td align="center" bgcolor="#FFFFFF">
                                N/A
                              </td>
                              <td align="center" bgcolor="#FFFFFF">
                                <input
                                  type="text"
                                  name="exch[1002][1000]"
                                  value="0.00"
                                  size="5"
                                  className="inpts"
                                />
                              </td>
                              <td align="center" bgcolor="#FFFFFF">
                                <input
                                  type="text"
                                  name="exch[1002][1001]"
                                  value="0.00"
                                  size="5"
                                  className="inpts"
                                />
                              </td>
                              <td align="center" bgcolor="#FFFFFF">
                                <input
                                  type="text"
                                  name="set_row_1002"
                                  value=""
                                  size="5"
                                  className="inpts"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td align="center" bgcolor="#FFFFFF">
                                <img src={BTC} height="17" />
                              </td>
                              <td align="center" bgcolor="#FFFFFF">
                                <input
                                  type="text"
                                  name="exch[1000][48]"
                                  value="0.00"
                                  size="5"
                                  className="inpts"
                                />
                              </td>
                              <td align="center" bgcolor="#FFFFFF">
                                <input
                                  type="text"
                                  name="exch[1000][68]"
                                  value="0.00"
                                  size="5"
                                  className="inpts"
                                />
                              </td>
                              <td align="center" bgcolor="#FFFFFF">
                                <input
                                  type="text"
                                  name="exch[1000][69]"
                                  value="0.00"
                                  size="5"
                                  className="inpts"
                                />
                              </td>
                              <td align="center" bgcolor="#FFFFFF">
                                <input
                                  type="text"
                                  name="exch[1000][1002]"
                                  value="0.00"
                                  size="5"
                                  className="inpts"
                                />
                              </td>
                              <td align="center" bgcolor="#FFFFFF">
                                N/A
                              </td>
                              <td align="center" bgcolor="#FFFFFF">
                                <input
                                  type="text"
                                  name="exch[1000][1001]"
                                  value="0.00"
                                  size="5"
                                  className="inpts"
                                />
                              </td>
                              <td align="center" bgcolor="#FFFFFF">
                                <input
                                  type="text"
                                  name="set_row_1000"
                                  value=""
                                  size="5"
                                  className="inpts"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td align="center" bgcolor="#FFFFFF">
                                <img src={NET} height="17" />
                              </td>
                              <td align="center" bgcolor="#FFFFFF">
                                <input
                                  type="text"
                                  name="exch[1001][48]"
                                  value="0.00"
                                  size="5"
                                  className="inpts"
                                />
                              </td>
                              <td align="center" bgcolor="#FFFFFF">
                                <input
                                  type="text"
                                  name="exch[1001][68]"
                                  value="0.00"
                                  size="5"
                                  className="inpts"
                                />
                              </td>
                              <td align="center" bgcolor="#FFFFFF">
                                <input
                                  type="text"
                                  name="exch[1001][69]"
                                  value="0.00"
                                  size="5"
                                  className="inpts"
                                />
                              </td>
                              <td align="center" bgcolor="#FFFFFF">
                                <input
                                  type="text"
                                  name="exch[1001][1002]"
                                  value="0.00"
                                  size="5"
                                  className="inpts"
                                />
                              </td>
                              <td align="center" bgcolor="#FFFFFF">
                                <input
                                  type="text"
                                  name="exch[1001][1000]"
                                  value="0.00"
                                  size="5"
                                  className="inpts"
                                />
                              </td>
                              <td align="center" bgcolor="#FFFFFF">
                                N/A
                              </td>
                              <td align="center" bgcolor="#FFFFFF">
                                <input
                                  type="text"
                                  name="set_row_1001"
                                  value=""
                                  size="5"
                                  className="inpts"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td bgcolor="#FFFFFF" nowrap="" align="center">
                                Set Col
                              </td>
                              <td bgcolor="#FFFFFF" align="center">
                                <input
                                  type="text"
                                  name="set_col_48"
                                  value=""
                                  size="5"
                                  className="inpts"
                                />
                              </td>
                              <td bgcolor="#FFFFFF" align="center">
                                <input
                                  type="text"
                                  name="set_col_68"
                                  value=""
                                  size="5"
                                  className="inpts"
                                />
                              </td>
                              <td bgcolor="#FFFFFF" align="center">
                                <input
                                  type="text"
                                  name="set_col_69"
                                  value=""
                                  size="5"
                                  className="inpts"
                                />
                              </td>
                              <td bgcolor="#FFFFFF" align="center">
                                <input
                                  type="text"
                                  name="set_col_1002"
                                  value=""
                                  size="5"
                                  className="inpts"
                                />
                              </td>
                              <td bgcolor="#FFFFFF" align="center">
                                <input
                                  type="text"
                                  name="set_col_1000"
                                  value=""
                                  size="5"
                                  className="inpts"
                                />
                              </td>
                              <td bgcolor="#FFFFFF" align="center">
                                <input
                                  type="text"
                                  name="set_col_1001"
                                  value=""
                                  size="5"
                                  className="inpts"
                                />
                              </td>
                              <td bgcolor="#FFFFFF" align="center">
                                <input
                                  type="button"
                                  value="Set"
                                  className="sbmt"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </th>
                    </tr>
                  </tbody>
                </table>
                <br />
                <input
                  type="text"
                  name="toall"
                  value="1.00"
                  size="5"
                  className="inpts"
                />
                <input type="button" value="Set to All" className="sbmt" />
                <br /> <br />
                <input type="submit" value="Update" className="sbmt" />
            </form>
            <br />
            <br />
            <div className="alert alert-warning">
              Exchange Rates:
              <br />
              <br />
              Figures are the percents of an exchange rates.
              <br /> Vertical column is FROM currency.
              <br /> Horizontal row is TO currency.
              <br /> <br /> Ex: To set a percent for Payeer to Perfect Money
              exchange you should edit the field in the vertical column with the
              Payeer icon and in the row with the PM one.
              <br /> <br /> To disable an exchange set its percentage to 100.
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default ExchangeRate;
