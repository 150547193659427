import React from 'react'

const AutoPay = () => {
    return (
        <table width="100%" height="100%" border="0" className="forTexts"> <tbody><tr> <td width="100%" height="100%" valign="top">  <h3>Auto-Withdrawals Settings:</h3>      <div className="alert alert-warning"> <b>We recommend to use the auto-payment feature only on the dedicated servers. Virtual Shared Hosting has much less security. <br />Use Mass Payment tool instead <a href="?a=withdrawal_requests">here</a>.</b> </div>  
        

 <form method="post" name="formsettings" onsubmit="return check_asettings()">
     
     <br /> <table className="form"> <tbody><tr> <th>Auto-Withdrawals Status</th> <td> <select name="use_auto_payment" className="inpts"> <option value="0" selected="" style={{color:"blue"}}>Disabled</option> <option value="1" style={{color:"red"}}>Enabled</option> </select> </td></tr> </tbody></table>  <table className="form"> <tbody><tr> <th colspan="2">Other Settings:</th> </tr>  <tr> <th>Processings:</th> <td className="alert alert-danger"><a href="?a=processings" targe="_blank">Processings setup is here</a></td> </tr> <tr> <th>Maximal daily withdrawal for a user for all currencies:</th> <td>$<input type="text" name="max_auto_withdraw_user" value="150.00" className="inpts nosize" size="30" /></td> </tr>  <tr> <th></th> <td><input type="submit" value="Save" className="sbmt" /></td> </tr> </tbody></table> </form>  <div className="alert alert-warning">  Maximal daily withdrawal for every user. The script will make payments to the user's payment account automatically if the total user withdrawal amount for 24 hour is less than the specified value.<br /> <br /> </div>  </td> </tr> </tbody></table>
    )
}

export default AutoPay
