import React from 'react';
import Btc from "../../btc.gif"

const ExpiryDeposit = () => {
  return (
    <table
      width="100%"
      height="100%"
      border="0"
      cellPadding="10"
      cellSpacing="0"
      className="forTexts"
    >
      {' '}
      <tbody>
        <tr>
          {' '}
          <td width="100%" height="100%" valign="top">
            {' '}
            <h3>Deposits:</h3>
            <table className="list">
              {' '}
              <tbody>
                <tr>
                  {' '}
                  <th>User</th> <th>Plan</th> <th>Deposit</th> <th>Expires</th>{' '}
                  <th>Action</th>{' '}
                </tr>{' '}
                <tr className="row1">
                  {' '}
                  <td>sawakotobe@gmail.com</td> <td>24% after 4 days (4)</td>{' '}
                  <td align="right" nowrap="">
                    $17000.00{' '}
                    <img
                      src={Btc}
                      alt=""
                      height="17"
                      align="absmiddle"
                    />
                  </td>{' '}
                  <td align="right">18 hours</td>{' '}
                  <td nowrap="">
                    <a
                      href="?a=user_edit&amp;id=113"
                      target="_blank"
                      className="sbmt btn-sm btn-success"
                    >
                      account
                    </a>{' '}
                    <a
                      href="?a=user_details&amp;id=113"
                      target="_blank"
                      className="sbmt btn-sm btn-info"
                    >
                      funds
                    </a>
                  </td>{' '}
                </tr>{' '}
              </tbody>
            </table>{' '}
            <br />{' '}
          </td>{' '}
        </tr>
      </tbody>
    </table>
  );
};

export default ExpiryDeposit;
