import React from 'react'
import Classes from "./input.module.css"

const Input = ({label, inputType, onChange, value, type, options=[], style, placeholder, required}) => {
    return (
        <div className={Classes.mainCover}>
            <label className={Classes.label} htmlFor={label}>{label}</label>
            {type === "input" && <input required={required} placeholder={placeholder} className={Classes.inputCover} style={style} value={value} onChange={onChange} type={inputType} />}
            {type === "select" && 
            <select className={Classes.inputCover} style={style}  onChange={onChange}>
                    {options.map((v,i) => (
                        <option key={v,i} value={v}>{v}</option>
                    ))}
                </select>}
            {type === "textarea" && <textarea placeholder="Short description of store" style={{height: "auto"}} className={Classes.inputCover}  rows="3"></textarea>}

        </div>
    )
}

export default Input
