import React from 'react'

const UserReferral = () => {
    return (
        <table width="100%" height="100%" border="0" cellpadding="10" cellspacing="0" class="forTexts"> <tbody><tr> <td width="100%" height="100%" valign="top">  <h3>User Referrals:</h3>    <form method="post">
             <table class="form"> <tbody><tr> <th>Username:</th> <td>Grace11</td> </tr> <tr> <th>Upline:</th> <td><input type="text" name="upline" value="" class="inpts" style={{width: "70%"}} /> <input type="submit" value="Update" class="sbmt" /></td> </tr> <tr> <th>Direct Referrals:</th> <td>Active: <b>0</b> / Total: <b>1</b></td> </tr> <tr> <th>None Direct Referrals:</th> <td>Active: <b>0</b> / Total: <b>0</b></td> </tr>  </tbody></table> </form> <br /><br /> <table class="list"> <tbody><tr> <th>User</th> <th>Status</th> <th>Stats</th> <th>Action</th> </tr>  <tr class="row1"> <td align="center"> <a href="?a=user_details&amp;id=135" target="_blank" class="link username">Julian11</a><br /> <i>Julian</i><br /> <small>slynn8640@gmail.com</small> </td> <td align="center"><span class="badge-bg badge-info">Free</span></td> <td> Deposit: <b>$0.00</b>   </td> <td align="center"><a href="?a=user_refs&amp;action=remove_ref&amp;id=134&amp;ref=135&amp;form_id=16424477209009&amp;form_token=140f77230a1def50f44c801451a260e7" class="sbmt btn-sm btn-danger" onclick="return confirm('Are you sure to delete this referral?');">unlink</a></td> </tr>  </tbody></table>  </td> </tr> </tbody></table>
    )
}

export default UserReferral
