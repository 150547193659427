import React, { useEffect, useState, useContext } from 'react';
import Classes from './gettingStarted.module.css';
import toast, { Toaster } from "react-hot-toast";
import {MainContext} from "../../App"

const GettingStarted = () => {
  const [loading, setLoading] = useState(false)
  const CTX = useContext(MainContext)
  const [data, setData] = useState()

  const fetchIndexDetails =() => {
    setLoading(true)
    let TOASTID = toast.loading("Settings up contents");
    fetch(`${CTX.url}admin/action/view-home`, {
      method: "GET",
      headers: new Headers({"Content-Type" : "application/json", 
      "Authorization": `bearer ${CTX.token}`}),
    })
    .then(res => res.json())
    .then(res => {
    setLoading(false)
    toast.dismiss(TOASTID); // stop the loading icon
    toast.success("Contents loaded successfully");
    setData(res)
    // setCurrentLimit(res.previous_page * limit)
  })
  .catch(e => {
      setLoading(false)
      toast.dismiss(TOASTID);
      toast.error("Error check your internet connection");
      console.log(e);
    })
  }

  useEffect(() => {
    fetchIndexDetails()
  }, [])
  return (
    <table
      width="100%"
      height="100%"
      border="0"
      cellpadding="10"
      cellspacing="0"
      className="forTexts"
    >
      <tbody>
        <tr>
          {!loading &&<td width="100%" height="100%" valign="top">
            <h3>Information</h3>

            <table className="form">
               
              <tbody>
                <tr>
                   
                  <th>Users:</th> 
                  <td>
                     
                    <a
                      data-hlp="Total users registered in the system"
                      className="hlp badge badge-info"
                    >
                      {data?.allUser}
                    </a> 
                    {/* <a
                      data-hlp="How many active users does your system contain"
                      className="hlp badge badge-success"
                    >
                      97 (96%)
                    </a> 
                    <a
                      data-hlp="How many users are suspended (able to login but can not earn any funds from principal)"
                      className="hlp badge badge-warning"
                    >
                      1 (0%)
                    </a> 
                    <a
                      data-hlp="How many users are disabled (cannot login and can not earn any funds from principal)"
                      className="hlp badge badge-danger"
                    >
                      3 (2%)
                    </a>  */}
                  </td> 
                </tr> 
                <tr>
                   
                  <th>Active Users:</th> 
                  <td>
                     
                    <a
                      data-hlp="How many users have ever made a deposit"
                      className="hlp badge badge-success"
                    >
                       
                      {data?.allActivateUser}
                    </a> 
                   
                  </td> 
                </tr> 
                {/* <tr>
                   
                  <th>Investment Packages:</th> 
                  <td>
                     
                    <a
                      data-hlp="Active investment packages number. Active users earn if they have deposited funds in these packages."
                      className="hlp badge badge-success"
                    >
                      3
                    </a> 
                  </td> 
                </tr>  */}
                <tr>
                   
                  <th>Withdrawal Requests</th> 
                  <td>
                     
                    <a
                      href="?a=withdrawal_requests"
                      className="hlp badge badge-danger"
                    >
                      {data?.allwithdrawalRequest}
                    </a> 
                  </td> 
                </tr> 
                <tr>
                   
                  <th>Pending Deposits</th> 
                  <td>
                     
                    <a
                      href="?a=pending_deposits"
                      className="hlp badge badge-success"
                    >
                     {data?.allDepositRequest}
                    </a> 
                  </td> 
                </tr> 
              </tbody>
            </table>












          </td>}
        </tr>
      </tbody>
    </table>
  );
};

export default GettingStarted;
