import React from 'react'

const Notice = () => {
    return (
        <table width="100%" height="100%" border="0" class="forTexts"> <tbody><tr> <td width="100%" height="100%" valign="top">  <h3>User Notices:</h3>   <div class="alert alert-warning"> Here you can send notices to your users.<br /> You can specify list of usernames and system will create notices for each user in the list.<br /> </div> <h3>Add a Notice:</h3> <form method="post">
             <table class="form"> <tbody><tr> <th>Notice Title:</th> <td><input type="text" name="title" value="" class="inpts"/></td> </tr> <tr> <th>Start Date:</th> <td><input type="text" name="date" value="2022-01-17 04:00:04" class="inpts" /></td> </tr> <tr> <th>Expires in:</th> <td><input type="text" name="expiration" value="0" class="inpts nosize" size="4" /> days <small>(set 0 to skip limitation)</small></td> </tr> <tr> <th>Users:</th> <td><input type="text" name="user" value="" class="inpts" size="60" /><br /><small>Leave blank to show message to all users or enter usernames divided by comma</small></td> </tr> <tr> <th>Notice:</th> <td><textarea name="text" class="inpts" rows="3"></textarea></td> </tr> <tr> <th></th> <td><input type="submit" value="Add" class="sbmt" /></td> </tr> </tbody></table> </form>  </td> </tr> </tbody></table>
    )
}

export default Notice
