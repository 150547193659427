import React from 'react'

const UserTraffic = () => {
    return (
        <table width="100%" height="100%" class="forTexts"> <tbody><tr> <td width="100%" height="100%" valign="top">  <h3>User Traffic:</h3> <table class="form"> <tbody><tr> <th>Username:</th> <td>Grace11</td> </tr> <tr> <th>Upline:</th> <td><i>N/A</i></td> </tr> <tr> <th>Direct Referrals:</th> <td>Active: <b>0</b> / Total: <b>1</b></td> </tr> <tr> <th>Total Commissions:</th> <td><b>$0.00</b></td> </tr> </tbody></table>  <br /><br /> <form method="post">
            
             
             </form> <table class="list"> <tbody><tr> <th>Date</th> <th>Traffic</th> <th>Signups</th> </tr>  <tr class="row1"> <td class="item" align="center">Jan-12-2022</td> <td class="item" align="center">3</td> <td class="item" align="center">0</td> </tr>  <tr class="row2"> <td class="item" align="center">Jan-15-2022</td> <td class="item" align="center">0</td> <td class="item" align="center">1</td> </tr>  </tbody></table>  </td> </tr> </tbody></table>
    )
}

export default UserTraffic
