import React from 'react'
import BTC  from "../../btc.gif"

const AddFund = () => {
    return (
        <table width="100%" height="100%" border="0" cellpadding="10" cellspacing="0" class="forTexts"> <tbody><tr> <td width="100%" height="100%" valign="top">
                <h3>Transactions History:</h3>




<table className="list">
<tbody>
<tr> <th>UserName</th> <th>Amount</th> <th>Date</th> </tr>

<tr class="row1"> <td class="trn_user">  <b>sawakotobe@gmail.com</b> <a class="badge badge-danger" target="_blank" href="?a=user_edit&amp;id=113">edit</a> <a class="badge badge-info" target="_blank" href="?a=user_details&amp;id=113">manage</a> <br /> <small style={{color:"gray"}}><b>Transfer from external processings:</b> &nbsp; </small> </td> <td nowrap="" align="right"><b style={{color:"green"}}>$17000.00</b> <img src={BTC} align="absmiddle" hspace="1" height="17" /></td> <td align="center"><small>Jan-10-2022<br />11:08:57 AM</small></td> </tr>


<tr class="row1"> <td class="trn_user">  <b>sawakotobe@gmail.com</b> <a class="badge badge-danger" target="_blank" href="?a=user_edit&amp;id=113">edit</a> <a class="badge badge-info" target="_blank" href="?a=user_details&amp;id=113">manage</a> <br /> <small style={{color:"gray"}}><b>Transfer from external processings:</b> &nbsp; </small> </td> <td nowrap="" align="right"><b style={{color:"green"}}>$17000.00</b> <img src={BTC} align="absmiddle" hspace="1" height="17" /></td> <td align="center"><small>Jan-10-2022<br />11:08:57 AM</small></td> </tr>



<tr class="row1"> <td class="trn_user">  <b>sawakotobe@gmail.com</b> <a class="badge badge-danger" target="_blank" href="?a=user_edit&amp;id=113">edit</a> <a class="badge badge-info" target="_blank" href="?a=user_details&amp;id=113">manage</a> <br /> <small style={{color:"gray"}}><b>Transfer from external processings:</b> &nbsp; </small> </td> <td nowrap="" align="right"><b style={{color:"green"}}>$17000.00</b> <img src={BTC} align="absmiddle" hspace="1" height="17" /></td> <td align="center"><small>Jan-10-2022<br />11:08:57 AM</small></td> </tr>


<tr> <td><b>Total for selected options:</b></td> <td align="right" nowrap=""> $24621.91 <img src={BTC} align="absmiddle" /><br /> </td> <td></td> </tr>

</tbody>
</table>



<center> <ul class="pagination"><li class="page-item"><a class="prev page-link disabled">&lt;&lt;</a></li><li class="page-item active"><a class="page-link">1</a></li><li class="page-item"><a class="next page-link disabled">&gt;&gt;</a></li></ul> </center>


            </td>
            </tr>
            </tbody>
            </table>
    )
}

export default AddFund
