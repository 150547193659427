import React from 'react'
import BTC  from "../../btc.gif"


const Earnings = () => {
    return (
        <table width="100%" height="100%" border="0" cellpadding="10" cellspacing="0" className="forTexts"> <tbody><tr> <td width="100%" height="100%" valign="top">  <h3>Earning History:</h3>
        <form method="post" name="trans"> <table className="form controls nosize"> <tbody>
            
        <tr> <td> 
            
            
            <br /> <select name="ec" class="inpts" onchange="document.trans.submit()"> <option value="-1">All eCurrencies</option>  <option value="48">Bitcoin</option>  <option value="68">Litecoin</option>  <option value="69">Ethereum</option>  <option value="1002">Ethereum</option>  <option value="1000">Bitcoin</option>  <option value="1001"></option>  <option value="0" selected=""></option>  </select> </td> </tr>

             </tbody></table> </form>
                
                
        
        
        
        
        
        
                <table className="list">
                    <tbody>
                    <tr> <th>UserName</th> <th>Amount</th> <th>Date</th> </tr>
        
                   
                    {/* <tr className="row1"> <td className="trn_user">  <b>sawakotobe@gmail.com</b> <a className="badge badge-danger" target="_blank" href="?a=user_edit&amp;id=113">edit</a> <a className="badge badge-info" target="_blank" href="?a=user_details&amp;id=113">manage</a> <br /> <small style={{color:"gray"}}><b>Earning:</b> &nbsp; Earning from deposit $17000.00 - 24.00 %</small> </td> <td nowrap="" align="right"><b style={{color:"green"}}>$4080.00</b> <img src={BTC} align="absmiddle" hspace="1" height="17" /></td> <td align="center"><small>Jan-14-2022<br />11:08:57 AM</small></td> </tr>

                    <tr className="row1"> <td className="trn_user">  <b>sawakotobe@gmail.com</b> <a className="badge badge-danger" target="_blank" href="?a=user_edit&amp;id=113">edit</a> <a className="badge badge-info" target="_blank" href="?a=user_details&amp;id=113">manage</a> <br /> <small style={{color:"gray"}}><b>Earning:</b> &nbsp; Earning from deposit $17000.00 - 24.00 %</small> </td> <td nowrap="" align="right"><b style={{color:"green"}}>$4080.00</b> <img src={BTC} align="absmiddle" hspace="1" height="17" /></td> <td align="center"><small>Jan-14-2022<br />11:08:57 AM</small></td> </tr>

                    <tr className="row1"> <td className="trn_user">  <b>sawakotobe@gmail.com</b> <a className="badge badge-danger" target="_blank" href="?a=user_edit&amp;id=113">edit</a> <a className="badge badge-info" target="_blank" href="?a=user_details&amp;id=113">manage</a> <br /> <small style={{color:"gray"}}><b>Earning:</b> &nbsp; Earning from deposit $17000.00 - 24.00 %</small> </td> <td nowrap="" align="right"><b style={{color:"green"}}>$4080.00</b> <img src={BTC} align="absmiddle" hspace="1" height="17" /></td> <td align="center"><small>Jan-14-2022<br />11:08:57 AM</small></td> </tr>

                    <tr className="row1"> <td className="trn_user">  <b>sawakotobe@gmail.com</b> <a className="badge badge-danger" target="_blank" href="?a=user_edit&amp;id=113">edit</a> <a className="badge badge-info" target="_blank" href="?a=user_details&amp;id=113">manage</a> <br /> <small style={{color:"gray"}}><b>Earning:</b> &nbsp; Earning from deposit $17000.00 - 24.00 %</small> </td> <td nowrap="" align="right"><b style={{color:"green"}}>$4080.00</b> <img src={BTC} align="absmiddle" hspace="1" height="17" /></td> <td align="center"><small>Jan-14-2022<br />11:08:57 AM</small></td> </tr> */}

<tr><td>No Account Found</td></tr>
        
                    {/* <tr> <td><b>Total for selected options:</b></td> <td align="right" nowrap=""> $10152.46 <img src={require("../../btc.gif")} align="absmiddle" /><br /> </td> <td></td> </tr> */}
        
        
        
                    </tbody>
                </table>
        
        
{/*         
                <center> <ul className="pagination"><li className="page-item"><a className="prev page-link disabled">&lt;&lt;</a></li><li className="page-item active"><a className="page-link">1</a></li><li className="page-item"><a className="page-link" href="javascript:go(2)">2</a></li><li className="page-item"><a className="page-link" href="javascript:go(3)">3</a></li><li className="page-item"><a className="next page-link" href="javascript:go(2)">&gt;&gt;</a></li><li className="page-item"><a className="last page-link" href="javascript:go(3)">&gt;&gt;&gt;</a></li></ul> </center> */}
                
                
        
                </td>
                </tr>
                </tbody>
                </table>
    )
}

export default Earnings
