import React from 'react'

const InfoBox = () => {
    return (
        <table width="100%" height="100%" border="0" className="forTexts"> <tbody><tr> <td width="100%" height="100%" valign="top">  <h3>Info Box Settings:</h3>   <form method="post" name="menuf">
            
            <table className="form"> <tbody><tr> <th>Show Info Box:</th> <td> <select name="show_info_box" className="inpts"> <option value="0">Hide</option> <option value="1" selected="">Show</option> </select> </td> </tr> <tr>
                
                <th>Start Date:</th> <td> <select name="show_info_box_started" className="inpts"> <option value="0">Hide</option> <option value="1" selected="">Show</option> </select> </td> </tr> <tr> <th>Running days:</th> <td> <select name="show_info_box_running_days" className="inpts"> <option value="0">Hide</option> <option value="1" selected="">Show</option> </select> </td> </tr> <tr> <th>Total Accounts:</th> <td> <select name="show_info_box_total_accounts" className="inpts"> <option value="0">Hide</option> <option value="1" selected="">Show</option> </select> </td> </tr> <tr> <th>VIP Accounts:</th> <td> <select name="show_info_box_vip_accounts" className="inpts"> <option value="0" selected="">Hide</option> <option value="1">Show</option> </select> </td> </tr> <tr> <th>VIP Account Min Deposit:</th> <td> <input type="text" name="vip_users_deposit_amount" value="0" className="inpts" /> </td> </tr> <tr> <th>Total Deposits:</th> <td> <select name="show_info_box_deposit_funds" className="inpts">
                    
                     <option value="0">Hide</option> <option value="1" selected="">Show</option> </select> </td> </tr> <tr> <th>Today Deposits:</th> <td> <select name="show_info_box_today_deposit_funds" className="inpts"> <option value="0" selected="">Hide</option> <option value="1">Show</option> </select> </td> </tr> <tr> <th>Total Withdrawals:</th> <td> <select name="show_info_box_total_withdraw" className="inpts"> <option value="0">Hide</option> <option value="1" selected="">Show</option> </select> </td> </tr> <tr> <th>Today Withdrawals:</th> <td> <select name="show_info_box_today_withdraw_funds" className="inpts"> <option value="0" selected="">Hide</option> <option value="1">Show</option> </select> </td> </tr> <tr> <th>Visitors Online:</th> <td> <select name="show_info_box_visitor_online" className="inpts"> <option value="0">Hide</option> <option value="1" selected="">Show</option> </select> </td> </tr> <tr> <th>Members Online:</th> <td> <select name="show_info_box_members_online" className="inpts"> <option value="0">Hide</option> 
                     
                     <option value="1" selected="">Show</option> </select> </td> </tr> <tr> <th>The Newest Member:</th> <td> <select name="show_info_box_newest_member" className="inpts"> <option value="0" selected="">Hide</option> <option value="1">Show</option> </select> </td> </tr> <tr> <th>Last Withdrawal:</th> <td> <select name="show_info_box_lastwithdrawal" className="inpts"> <option value="0" selected="">Hide</option> <option value="1">Show</option> </select> </td> </tr> <tr> <th>Last Deposit:</th> <td> <select name="show_info_box_lastdeposit" className="inpts"> <option value="0">Hide</option> <option value="1" selected="">Show</option> </select> </td> </tr> <tr> <th>Last Topup:</th> <td> <select name="show_info_box_lastaddfunds" className="inpts"> <option value="0" selected="">Hide</option> <option value="1">Show</option> </select> </td> </tr> <tr> <th>Last Update Date:</th> <td> <select name="show_info_box_last_update" className="inpts">
                         
                          <option value="0">Hide</option> <option value="1" selected="">Show</option> </select> </td> </tr> </tbody></table> <div className="alert alert-warning"> These info avaliable an every page. Please note: each option takes time to be generated, do not enable ones you do not use. </div> <br /> 
                          
                          <table className="form"> <tbody><tr> <th colspan="2">Last &amp; Tops:</th> </tr> <tr> <th>Number of Last Topups:</th> <td><input type="text" name="index_last_add_funds" value="0" className="inpts" /></td> </tr> <tr> <th>Number of Last Deposits:</th> <td>
                              
                              <input type="text" name="index_last_deposits" value="0" className="inpts" /></td> </tr> <tr> <th>Number of Last Withdrawals:</th> <td><input type="text" name="index_last_withdrawals" value="0" className="inpts" />
                          
                          
                          
                          </td> </tr> <tr> <th>Number of Top Referrals:</th> <td><input type="text" name="index_top_referrals" value="0" className="inpts" /></td> </tr> <tr> <th>Number of Top Investors:</th> <td><input type="text" name="index_top_investors" value="0" className="inpts" /></td> </tr> <tr> <th>Number of Last Members:</th> <td><input type="text" name="index_last_members" value="0" className="inpts" /></td> </tr> </tbody></table> <div className="alert alert-warning"> These info avaliable an every page. Please note: each option takes time to be generated, do not enable ones you do not use. </div> <br /> <table className="form"> <tbody><tr> <th colspan="2">Stats Pages:</th> </tr> <tr> <th>Show Stats Links Box:</th> <td> <select name="show_stats_box" className="inpts"> <option value="0">Hide</option> <option value="1" selected="">Show</option> </select> </td> </tr> <tr> <th>Investors Page:</th> <td> <select name="show_members_stats" className="inpts"> <option value="0">Disable</option> <option value="1" selected="">Enable</option> </select> </td>
                     
                      </tr> <tr> <th>Paidouts Page:</th> <td> <select name="show_paidout_stats" className="inpts"> <option value="0">Disable</option> <option value="1" selected="">Enable</option> </select> </td> </tr> <tr> <th>Top Investors Page:</th> <td> <select name="show_top10_stats" className="inpts"> <option value="0">Disable</option> <option value="1" selected="">Enable</option> </select> </td> </tr> <tr> <th>Last Investors Page:</th> <td> <select name="show_last10_stats" className="inpts"> <option value="0">Disable</option> <option value="1" selected="">Enable</option> </select> </td> </tr> <tr> <th>Top Referrers Page:</th> <td> <select name="show_refs10_stats" className="inpts"> <option value="0" selected="">Disable</option> <option value="1">Enable</option> </select> </td> </tr> <tr> <th>Top Referrers Page:</th> <td> <select name="show_refs10_stats" className="inpts"> <option value="0" selected="">Disable</option> <option value="1">Enable</option> </select> <table className="form&quot;"> <tbody><tr> <th>Users number on the page:</th> <td><input type="text" name="refs10_qusers" value="0" className="inpts" /></td> </tr> <tr> <th>Reset date:<br /><i>yyyy-mm-dd</i></th> <td><input type="text" name="refs10_start_date" value="0000-00-00" className="inpts" /></td> </tr> </tbody></table> </td> </tr> </tbody></table> <br /><br /> <center> <input type="submit" className="sbmt btn-success" value="Save" /> </center> </form>  </td> </tr> </tbody></table>
    )
}

export default InfoBox
