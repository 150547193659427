import React from 'react'

const Security = () => {
    return (
        <table width="100%" height="100%" border="0" class="forTexts"> <tbody><tr> <td width="100%" height="100%" valign="top">  <h3>Security</h3>    <h3>Advanced login security settings:</h3> <form method="post">
            
            <table class="form"> <tbody><tr> <th>Detect IP Address Change Sensitivity:</th> <td> <input type="radio" name="ip" value="disabled" checked="" />Disabled<br /> <input type="radio" name="ip" value="medium" />Medium<br /> <input type="radio" name="ip" value="high" />High<br /> <input type="radio" name="ip" value="always" />Paranoic<br /> </td> </tr> <tr> <th>Detect Browser Change:</th> <td> <input type="radio" name="browser" value="disabled" checked="" />Disabled<br />
            
             <input type="radio" name="browser" value="enabled" />Enabled<br /> </td> </tr> <tr> <th>E-mail:</th> <td><input type="text" name="email" value="admin@driiliinvestment.com" class="inpts" /></td> </tr> <tr> <th></th> <td><input type="submit" value="Set" class="sbmt" /></td> </tr>
             
              </tbody></table> </form> <h3>Monitor your template files:</h3> <div class="alert alert-info"> This function will monitor template files and once it changed, admin will receive e-mail about changes. It will protect your website against unauthorized changes of your template files. Note: files are checking once in minute if one user is browsing your website atleast. </div>
             
              <br />  <table class="form"> <tbody><tr> <th>Status</th> <td><span class="badge sticker badge-danger">Off</span> <a href="?a=security&amp;action=monitor_files&amp;status=1" class="sbmt btn btn-info">Turn on</a> </td> </tr> </tbody></table>   </td> </tr> </tbody></table>
    )
}

export default Security
