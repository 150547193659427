import React from 'react'
import BTC from '../../btc.gif';
import LITE from '../../litecoin.gif';
import ETH from '../../ethereum.gif';
import GOLD from '../../goldMoney.gif';
import NET from '../../netPay.gif';

const Processing = () => {
    return (
        <table width="100%" height="100%" border="0" className="forTexts"> <tbody>
            <tr> <td width="100%" height="100%">  <h3>Processings:</h3> 
            
            <table className="list">
                 <tbody><tr> <th>Name</th> <th>Icon</th> <th>Actions</th> </tr>  <tr> <td><span style={{fontWeight: "bold"}}>Bitcoin</span> </td>
                  <td align="center">
                      <img src={BTC} alt="Upload image &quot;48.gif&quot; to &quot;images&quot; folder" height="17" /></td> <td nowrap=""> <a href="?a=processings&amp;action=edit&amp;id=48" className="sbmt btn-sm btn-info">edit</a>  </td></tr>  <tr> <td><span style={{fontWeight: "bold"}}>Ethereum</span> </td> <td align="center">
                          
                          <img src={ETH} alt="Upload image &quot;69.gif&quot; to &quot;images&quot; folder" height="17" /></td> <td nowrap=""> <a href="?a=processings&amp;action=edit&amp;id=69" className="sbmt btn-sm btn-info">edit</a>  </td></tr>  <tr> <td><span style={{fontWeight: "bold"}}>Bitcoin</span> </td> <td align="center">
                              
                              <img src="images/1000.gif" alt="Upload image &quot;1000.gif&quot; to &quot;images&quot; folder" height="17" /></td> <td nowrap=""> <a href="?a=processings&amp;action=edit&amp;id=1000" className="sbmt btn-sm btn-info">edit</a>  </td></tr>  <tr> <td><span style={{fontWeight: "bold"}}>Ethereum</span> </td> <td align="center">
                                  
                                  <img src="images/1002.gif" alt="Upload image &quot;1002.gif&quot; to &quot;images&quot; folder" height="17" /></td> <td nowrap=""> <a href="?a=processings&amp;action=edit&amp;id=1002" className="sbmt btn-sm btn-info">edit</a>  <a href="?a=processings&amp;action=delete&amp;id=1002&amp;form_id=16421995692768&amp;form_token=59652830843a564a00fbf1d36a913835" onclick="return confirm('Do you really want to delete &quot;Ethereum&quot; processing?')" className="sbmt btn-sm btn-danger">delete</a></td>  </tr>  <tr> <td><span>PerfectMoney</span> </td> <td align="center"><img src="images/18.gif" alt="Upload image &quot;18.gif&quot; to &quot;images&quot; folder" height="17" />
                                  
                                  </td> <td nowrap=""> <a href="?a=processings&amp;action=edit&amp;id=18" className="sbmt btn-sm btn-info">edit</a>  </td></tr>  <tr> <td><span>Payeer</span> </td> <td align="center"><img src="images/43.gif" alt="Upload image &quot;43.gif&quot; to &quot;images&quot; folder" height="17" />
                                  
                                  </td> <td nowrap=""> <a href="?a=processings&amp;action=edit&amp;id=43" className="sbmt btn-sm btn-info">edit</a>  </td></tr>  <tr> <td><span>Litecoin</span> </td> <td align="center"><img src="images/68.gif" alt="Upload image &quot;68.gif&quot; to &quot;images&quot; folder" height="17" />
                                  
                                  </td> <td nowrap=""> <a href="?a=processings&amp;action=edit&amp;id=68" className="sbmt btn-sm btn-info">edit</a>  </td></tr>  <tr> <td><span>Dash</span> </td> <td align="center"><img src="images/71.gif" alt="Upload image &quot;71.gif&quot; to &quot;images&quot; folder" height="17" />
                                  
                                  </td> <td nowrap=""> <a href="?a=processings&amp;action=edit&amp;id=71" className="sbmt btn-sm btn-info">edit</a>  </td></tr>  <tr> <td><span>Dogecoin</span> </td> <td align="center"><img src="images/79.gif" alt="Upload image &quot;79.gif&quot; to &quot;images&quot; folder" height="17" />
                                  
                                  </td> <td nowrap=""> <a href="?a=processings&amp;action=edit&amp;id=79" className="sbmt btn-sm btn-info">edit</a>  </td></tr>  <tr> <td><span>Bitcoin Cash</span> </td> <td align="center"><img src="images/77.gif" alt="Upload image &quot;77.gif&quot; to &quot;images&quot; folder" height="17" />
                                  
                                  </td> <td nowrap=""> <a href="?a=processings&amp;action=edit&amp;id=77" className="sbmt btn-sm btn-info">edit</a>  </td></tr>  <tr> <td><span>Bitcoin Gold</span> </td> <td align="center"><img src="images/80.gif" alt="Upload image &quot;80.gif&quot; to &quot;images&quot; folder" height="17" />
                                  
                                  </td> <td nowrap=""> <a href="?a=processings&amp;action=edit&amp;id=80" className="sbmt btn-sm btn-info">edit</a>  </td></tr>  <tr> <td><span>PayPal</span> </td> <td align="center"><img src="images/6.gif" alt="Upload image &quot;6.gif&quot; to &quot;images&quot; folder" height="17" />
                                  
                                  </td> <td nowrap=""> <a href="?a=processings&amp;action=edit&amp;id=6" className="sbmt btn-sm btn-info">edit</a>  </td></tr>  <tr> <td><span>eeeCurrency</span> </td> <td align="center"><img src="images/8.gif" alt="Upload image &quot;8.gif&quot; to &quot;images&quot; folder" height="17" />
                                  
                                  </td> <td nowrap=""> <a href="?a=processings&amp;action=edit&amp;id=8" className="sbmt btn-sm btn-info">edit</a>  </td></tr>  <tr> <td><span>Webmoney</span> </td> <td align="center"><img src="images/20.gif" alt="Upload image &quot;20.gif&quot; to &quot;images&quot; folder" height="17" />
                                  
                                  </td> <td nowrap=""> <a href="?a=processings&amp;action=edit&amp;id=20" className="sbmt btn-sm btn-info">edit</a>  </td></tr>  <tr> <td><span>SolidTrustPay</span> </td> <td align="center"><img src="images/22.gif" alt="Upload image &quot;22.gif&quot; to &quot;images&quot; folder" height="17" /></td> <td nowrap=""> <a href="?a=processings&amp;action=edit&amp;id=22" className="sbmt btn-sm btn-info">edit</a>  </td></tr>  <tr> <td><span>NixMoney</span> </td> <td align="center"><img src="images/42.gif" alt="Upload image &quot;42.gif&quot; to &quot;images&quot; folder" height="17" />
                                  
                                  </td> <td nowrap=""> <a href="?a=processings&amp;action=edit&amp;id=42" className="sbmt btn-sm btn-info">edit</a>  </td></tr>  <tr> <td><span>InterKassa</span> </td> <td align="center"><img src="images/45.gif" alt="Upload image &quot;45.gif&quot; to &quot;images&quot; folder" height="17" />
                                  
                                  </td> <td nowrap=""> <a href="?a=processings&amp;action=edit&amp;id=45" className="sbmt btn-sm btn-info">edit</a>  </td></tr>  <tr> <td><span>QIWI</span> </td> <td align="center"><img src="images/46.gif" alt="Upload image &quot;46.gif&quot; to &quot;images&quot; folder" height="17" />
                                  
                                  </td> <td nowrap=""> <a href="?a=processings&amp;action=edit&amp;id=46" className="sbmt btn-sm btn-info">edit</a>  </td></tr>  <tr> <td><span>ePayCore</span> </td> <td align="center"><img src="images/51.gif" alt="Upload image &quot;51.gif&quot; to &quot;images&quot; folder" height="17" />
                                  
                                  </td> <td nowrap=""> <a href="?a=processings&amp;action=edit&amp;id=51" className="sbmt btn-sm btn-info">edit</a>  </td></tr>  <tr> <td><span>GoCoin</span> </td> <td align="center"><img src="images/52.gif" alt="Upload image &quot;52.gif&quot; to &quot;images&quot; folder" height="17" />
                                  
                                  </td> <td nowrap=""> <a href="?a=processings&amp;action=edit&amp;id=52" className="sbmt btn-sm btn-info">edit</a>  </td></tr>  <tr> <td><span>Entromoney</span> </td> <td align="center"><img src="images/53.gif" alt="Upload image &quot;53.gif&quot; to &quot;images&quot; folder" height="17" />
                                  
                                  </td> <td nowrap=""> <a href="?a=processings&amp;action=edit&amp;id=53" className="sbmt btn-sm btn-info">edit</a>  </td></tr>  <tr> <td><span>AdvCash</span> </td> <td align="center"><img src="images/56.gif" alt="Upload image &quot;56.gif&quot; to &quot;images&quot; folder" height="17" />
                                  
                                  </td> <td nowrap=""> <a href="?a=processings&amp;action=edit&amp;id=56" className="sbmt btn-sm btn-info">edit</a>  </td></tr>  <tr> <td><span>Skrill</span> </td> <td align="center"><img src="images/58.gif" alt="Upload image &quot;58.gif&quot; to &quot;images&quot; folder" height="17" />
                                  
                                  </td> <td nowrap=""> <a href="?a=processings&amp;action=edit&amp;id=58" className="sbmt btn-sm btn-info">edit</a>  </td></tr>  <tr> <td><span>Nitronpay</span> </td> <td align="center"><img src="images/59.gif" alt="Upload image &quot;59.gif&quot; to &quot;images&quot; folder" height="17" />
                                  
                                  </td> <td nowrap=""> <a href="?a=processings&amp;action=edit&amp;id=59" className="sbmt btn-sm btn-info">edit</a>  </td></tr>  <tr> <td><span>SaliPay</span> </td> <td align="center"><img src="images/61.gif" alt="Upload image &quot;61.gif&quot; to &quot;images&quot; folder" height="17" />
                                  
                                  </td> <td nowrap=""> <a href="?a=processings&amp;action=edit&amp;id=61" className="sbmt btn-sm btn-info">edit</a>  </td></tr>  <tr> <td><span>epay.com</span> </td> <td align="center"><img src="images/64.gif" alt="Upload image &quot;64.gif&quot; to &quot;images&quot; folder" height="17" />
                                  
                                  </td> <td nowrap=""> <a href="?a=processings&amp;action=edit&amp;id=64" className="sbmt btn-sm btn-info">edit</a>  </td></tr>  <tr> <td><span>S-Pay</span> </td> <td align="center"><img src="images/65.gif" alt="Upload image &quot;65.gif&quot; to &quot;images&quot; folder" height="17" />
                                  
                                  </td> <td nowrap=""> <a href="?a=processings&amp;action=edit&amp;id=65" className="sbmt btn-sm btn-info">edit</a>  </td></tr>  <tr> <td><span></span> </td> <td align="center"><img src="images/1001.gif" alt="Upload image &quot;1001.gif&quot; to &quot;images&quot; folder" height="17" />
                                  
                                  </td> <td nowrap=""> <a href="?a=processings&amp;action=edit&amp;id=1001" className="sbmt btn-sm btn-info">edit</a>  </td></tr>  </tbody></table> <br /> <input type="button" value="Add Processing" className="btn btn-success sbmt" /> <br /><br /> <div className="alert alert-warning"> You can add or edit any payment processing in this section by clicking the "edit" or "add new" link. You should provide the full instructions for a user to know how to make a deposit using the specified payment system.<br /><br /> Any processing you add can't allow users to deposit just by themselves. The administrator has to approve or delete any transaction and process all the users' withdrawal requests manually. </div>  </td> </tr> </tbody></table>
    )
}

export default Processing
