import React from 'react'
import { ReactComponent as AiOutlineLoading } from '../assets/loading.svg'
import Classes from "./loading.module.css"

const Loading = ({style, size, fill}) => {
    return (
            <AiOutlineLoading style={{...style}} size={size} fill={fill} className={Classes.loadingSVG}  />
    )
}

export default Loading
